/**
 * Set to true to do not use cache (for debugging purposes)
 */
const CACHE_DISABLED = false;

/**
 * Convert string length to kb
 * @param {} length 
 * @returns 
 */
const toKb = length => (length * 2) / 1024;

/**
 * Free up the requested size
 * @param {*} kbs 
 * @returns 
 */
const freeUpStorageSpace = (localStringCache, kbs) => {
    const cachedPaths = Object.keys(localStorage);

    // get files opened during this sessions
    const trackedPaths = Object.entries(localStringCache.pathsLastAccessTime).filter.sort((a, b) => a[1] - b[1]).map(([path,]) => path);
    // files opened in previous sessions (seems better to delete these ones before)
    const untrackedPaths = cachedPaths.filter(path => !localStringCache.pathsLastAccessTime.hasOwnProperty(path));

    const sortedPaths = [ ...untrackedPaths, ...trackedPaths ];
    if (sortedPaths.length === 0) return false;

    let totalLength = 0;
    let index = 0;
    while (totalLength < kbs && index < sortedPaths.length) {
        const path = sortedPaths[index];

        if (localStringCache.pathsLastAccessTime.hasOwnProperty(path)) {
            delete localStringCache.pathsLastAccessTime[path];
        }

        let objectLength = 0
        if (localStorage.hasOwnProperty(path)) {
            objectLength = toKb(localStorage[path].length + path.length);
            localStorage.removeItem(path);
        }

        totalLength += objectLength;
        index++;
    }
    console.log(`Total size free: ${totalLength.toFixed(2)}KB`);
    return true;
}

/**
 * 
 */
class LocalStringCache {

    constructor() {
        this.pathsLastAccessTime = {};
    }

    /**
     * 
     * @param {*} path 
     * @param {*} str 
     * @returns 
     */
    set(path, str) {
        this.pathsLastAccessTime[path] = Date.now();
        let stored = false;
        let canFreeSpace = true;
        while (!stored && canFreeSpace) {
            try {
                localStorage.setItem(path, str);
                stored = true;
            } catch (e) {
                canFreeSpace = freeUpStorageSpace(this, toKb(str.length) + 1024); // we free 1Mb more
            }
        }
        if (!stored) console.log(`Unable to store the file: ${path}`);
        return stored;
    }

    /**
     * 
     * @param {*} path 
     * @returns 
     */
    get(path) {
        if (!CACHE_DISABLED && localStorage.hasOwnProperty(path)) {
            this.pathsLastAccessTime[path] = Date.now();
            //console.log(`Read from cache: ${path}`);
            return localStorage.getItem(path);
        }
        return null;
    }

}

export default new LocalStringCache();