
import { produce } from "immer";
import * as React from 'react';
import InfiniteContext from './InfiniteContext.js';




/**
 * 
 * @param {*} rectA 
 * @param {*} rectB 
 * @returns 
 */
export const areRectsOverlaping = (rectA, rectB) => {
    if (!rectA || !rectB) return false;
    return !(rectA.x + rectA.width < rectB.x
        || rectA.x > rectB.x + rectB.width
        || rectA.y + rectA.height < rectB.y
        || rectA.y > rectB.y + rectB.height);
}

/**
 * 
 * @param {*} param0 
 * @param {*} param1 
 * @returns 
 */
export const getRectFromTwoCoordinates = ({ x: x1, y: y1 }, { x: x2, y: y2 }) => {
    return {
        x: Math.min(x1, x2),
        y: Math.min(y1, y2),
        width: Math.abs(x2 - x1),
        height: Math.abs(y2 - y1),
    }
}

/**
 * 
 * @param {*} rects 
 * @returns 
 */
export const getEncapsulatedRect = rects => {
    let rect = null;
    rects.forEach(r => {
        if (!rect) rect = r;
        else {
            const rx = rect.x, ry = rect.y, rw = rect.width, rh = rect.height;
            const minX = (r.x < rx) ? r.x : rx;
            const minY = (r.y < ry) ? r.y : ry;
            const maxX = (r.x + r.width > rx + rw) ? r.x + r.width : rx + rw;
            const maxY = (r.y + r.height > ry + rh) ? r.y + r.height : ry + rh;
            rect = { x: minX, y: minY, width: maxX - minX, height: maxY - minY };
        }
    });
    return rect;
}

/**
 * 
 */
class InfiniteProvider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // position
            container: { width: 0, height: 0, left: 0, top: 0 },
            delta: { x: 0, y: 0 },
            // selection
            selectingRect: null,
            selectingNodes: [],
            selectedNodes: [],
            // node sizes
            sizes: {},
            // node elements
            elements: {},
        }

        // background pattern size
        this.patternSize = {
            width: 16,
            height: 16,
        };

        // the grid
        this.grid = {
            x: 16,
            y: 16,
        }

        // nodes sizes (observed) (not it state to avoid multiple rerendered) 
        this.observer = new ResizeObserver((entries) => {
            this.setState(prevState => produce(prevState, draft => {
                for (let entry of entries) {
                    const { inlineSize: width, blockSize: height } = entry.contentBoxSize[0];
                    const nodeId = entry.target.nodeId;
                    draft.sizes[nodeId] = { width, height };
                }
            }));
        });
    }

    /**
     * 
     * @param {*} nodeId 
     * @param {*} element 
     */
    addNodeElement = (nodeId, element) => {
        this.observer.observe(element);
        element.nodeId = nodeId;
        this.setState(prevState => produce(prevState, draft => {
            draft.elements[nodeId] = element;
        }));
    }

    /**
     * 
     * @param {*} nodeId 
     */
    removeNodeElement = nodeId => {
        const element = this.state.elements[nodeId];
        this.observer.unobserve(element);
        this.setState(prevState => produce(prevState, draft => {
            delete draft.elements[nodeId];
        }));
    }

    /**
     * 
     * @param {*} positions 
     */
    moveNodes = positions => {
        const nodes = produce(this.props.nodes, draft => {
            Object.entries(positions).forEach(([nodeId, position]) => {
                draft[nodeId].x = Math.round(position.x / this.grid.x) * this.grid.x;
                draft[nodeId].y = Math.round(position.y / this.grid.y) * this.grid.y;
            })
        });
        // call the change listener
        if (this.props.onChange) {
            this.props.onChange(nodes);
        }
    }

    /**
     * 
     * @param {*} selectingRect 
     * @returns 
     */
    setSelectingRect = selectingRect => {
        const selectingNodes = this.getNodesInRect(selectingRect);
        return new Promise((resolve) => this.setState({ selectingRect, selectingNodes }, resolve));
    }

    /**
     * 
     * @param {*} selectedNodes 
     * @returns 
     */
    setSelectedNodes = (selectedNodes = []) => {
        return new Promise((resolve) => this.setState({ selectedNodes }, resolve));
    }

    /**
     * 
     * @returns 
     */
    computeOffsets = () => {
        // compute the selectedRect
        const rects = this.state.selectedNodes.map(nodeId => ({
            x: this.props.nodes[nodeId].x - this.state.sizes[nodeId].width / 2,
            y: this.props.nodes[nodeId].y - this.state.sizes[nodeId].height / 2,
            width: this.state.sizes[nodeId].width,
            height: this.state.sizes[nodeId].height,
        }));
        const rect = getEncapsulatedRect(rects);
        // set the offset to apply to all nodes
        const offsets = Object.keys(this.props.nodes).map(nodeId => {
            if (this.state.selectedNodes.includes(nodeId)) {
                return [nodeId, {
                    x: - rect.x,
                    y: - rect.y,
                }]
            } else {
                return [nodeId, {
                    x: this.state.delta.x - this.state.container.left,
                    y: this.state.delta.y - this.state.container.top,
                }]
            }
        });
        return Object.fromEntries(offsets)
    }



    /**
     * 
     * @param {*} rect 
     * @returns 
     */
    getNodesInRect = rect => {
        const nodeIds = [];
        Object.entries(this.props.nodes).forEach(([nodeId, p]) => {
            const s = this.state.sizes[nodeId];
            const nodeRect = {
                x: p.x - s.width / 2,
                y: p.y - s.height / 2,
                width: s.width,
                height: s.height,
            }
            if (areRectsOverlaping(nodeRect, rect)) {
                nodeIds.push(nodeId);
            }
        });
        return nodeIds;
    }

    /**
     * 
     * @param {*} param0 
     * @returns 
     */
    moveContainer = ({ x = 0, y = 0 }) => {
        // the container rect, here the screen size.
        var rect = {
            width: window.screen.width,
            height: window.screen.height,
        }
        // compute the width and height
        const width = rect.width + 2 * this.patternSize.width;
        const height = rect.height + 2 * this.patternSize.height;
        // compute the left and top
        const left = x + Math.floor(- (this.patternSize.width + x) / this.patternSize.width) * this.patternSize.width;
        const top = y + Math.floor(- (this.patternSize.height + y) / this.patternSize.height) * this.patternSize.height;
        // set in the global state the new offset and delta to be used by children
        return new Promise((resolve) => this.setState({
            delta: { x, y },
            container: { width, height, left, top },
        }, resolve));
    }

    /**
     * 
     * @returns 
     */
    render() {
        return <InfiniteContext.Provider value={{
            // Public methods - can be used outside this package.
            moveContainer: this.moveContainer.bind(this),
            setSelectedNodes: this.setSelectedNodes.bind(this),
            getNodesInRect: this.getNodesInRect.bind(this),
            // Private - should not be used by outside this package.
            addNodeElement: this.addNodeElement.bind(this),
            removeNodeElement: this.removeNodeElement.bind(this),
            moveNodes: this.moveNodes.bind(this),
            setSelectingRect: this.setSelectingRect.bind(this), // not realy usefull outside this package
            grid: this.grid,
            nodes: this.props.nodes,
            sizes: this.state.sizes,
            offsets: this.computeOffsets(),
            elements: this.state.elements,
            container: this.state.container,
            delta: this.state.delta,
            selectingRect: this.state.selectingRect, // TODO should we move in Infinte.js directly ?
            selectingNodes: this.state.selectingNodes,
            selectedNodes: this.state.selectedNodes,
        }}>
            {this.props.children}
        </InfiniteContext.Provider>
    }
};

export default InfiniteProvider;