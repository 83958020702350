import { mdiCodeBraces, mdiCodeBrackets, mdiFormatText, mdiNumeric } from '@mdi/js';
import { Icon } from '@mdi/react'; // Adaptez si vous utilisez une autre bibliothèque pour les icônes
import styled from 'styled-components';
import IconButton from './IconButton';
import { iconDelete } from '../icons/LightIcons';

const VariableIcons = {
    number: mdiNumeric,
    text: mdiFormatText,
}

// Styled-components
const Container = styled.div`
    height: 24px;
    display: flex;
    align-items: center;
    gap: 1px;
    &:hover > * {
        background-color: rgba(0, 0, 0, 0.04);
    }
    &:hover > *:last-child {
       opacity: 1;
    }
    & > * {
        transition: opacity background-color .2s;
    }
    & > *:last-child {
       opacity: 0;
    }
    cursor: default;
`;

// Styled-components
const ValueContainer = styled.div`
    height: 28px;
    display: flex;
    align-items: center;
    gap: 1px;
    &:hover > * {
        background-color: rgba(0, 0, 0, 0.04);
    }
    &:hover > *:last-child {
       opacity: 1;
    }
    & > * {
        transition: opacity background-color .2s;
    }
    & > *:last-child {
       opacity: 0;
    }
    cursor: default;

    & > *:first-child > *:last-child {
       transform: translateX(24px);
       transition: transform .2s;
    }

    &:hover > *:first-child > *:last-child {
        transform: translateX(0px);
    }
`;

const FlexItem = styled.div`
    height: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    gap: 6px;
`;

const IconWrapper = styled.div`
    //width: 14px;
    display: flex;
    align-items: center;
    color: #2874ca;
`;


const Text = styled.div`
    flex-grow: 1;
    color: #2874ca;
    //font-weight: 300;
    //font-size: 12px;
    //font-family: IBM Plex Mono, ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
`;

const IconButtonWrapper = styled.div`
    height: 100%;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
`;

const Wrapper = styled.div`
    height: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding: 0 6px 0 6px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    gap: 6px;
    transition: background-color .2s;
    &:hover {
        background-color: rgba(0,0,0,.05);
    }
`;


export const ListItem = ({ name, icon, onClick, onDelete }) => (
    <Container>
        <FlexItem onClick={onClick}>
            <Wrapper>
            <IconWrapper>
                <Icon path={mdiCodeBraces} size={0.8} />
            </IconWrapper>
            <Text>{name}</Text>
            </Wrapper>
        </FlexItem>
        <IconButtonWrapper>
            <IconButton height={'100%'} stickyLeft path={iconDelete} onClick={onDelete} />
        </IconButtonWrapper>
    </Container>
);


export const ListItemWithValue = ({ name, icon, value, onClick, onDelete }) => (
    <Container>
        <FlexItem onClick={onClick}>
            <IconWrapper>
                <Icon path={icon} size={0.8} />
            </IconWrapper>
            <Text>{name}</Text>
            <div>{value}</div>
        </FlexItem>
        <IconButtonWrapper>
            <IconButton height={'100%'} stickyLeft path={iconDelete} onClick={onDelete} />
        </IconButtonWrapper>
    </Container>
);
