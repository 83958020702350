import React from 'react';
import AppContext from '../../app/AppContext.js';
import { applyAppPlan, createAppPlan, getStack } from '../../backend/Api.js';
import TextPrimaryButton from '../../design/TextPrimaryButton.js';
import PopupProvider from '../../popup/PopupProvider.js';
import { chain, withContext } from '../../utils/WithContext.js';
import InfiniteProvider from '../../infinite/InfiniteProvider.js';
import Infinite from '../../infinite/Infinite.js';


const AppPage = chain(
    withContext(AppContext)(({ appId, app, state }) => ({ appId, app, state })),

)(class extends React.Component {

    constructor(props) {
        super(props);
    }

    handleDeploy = () => {
        return getStack(this.props.app.stackId).then(stack => {
            return createAppPlan(this.props.appId, {
                buildId: stack.lastBuild?.buildId
            }).then(plan => {
                console.log(plan);
                return applyAppPlan(this.props.appId, plan.planId);
            })
        })
    }

    render() {

        return (
            <PopupProvider>
                <div style={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    //background: '#f2f2f6',
                    //backgroundColor: '#EEEEFF',
                    //background: 'linear-gradient(140deg, rgb(230 234 239) 0%, rgb(239, 254, 239) 100%)',
                    backgroundImage: 'linear-gradient(to bottom, #dfe9f3 0%, white 100%)',
                    //background: '#eff3f6',//'#FCFCFC',


                }}>
                    <div>{JSON.stringify(this.props.state)}</div>
                    <br/><br/>
                    <div><TextPrimaryButton text={"Deploy last version"} onClick={this.handleDeploy} /></div>


                   



                </div>
            </PopupProvider>
        );
    }
});



export default AppPage;

