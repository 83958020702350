import {
    createStackBuild as _createStackBuild,
    downloadStackFile as _downloadStackFile,
    getStack as _getStack,
    getStackFiles as _getStackFiles,
    getStackPublication as _getStackPublication,
} from "./Api";
import BackendCache from "./BackendCache";
import localStringCache from "./LocalStringCache";
import { isBuildIdVersionFormat, isBuiltArtifactUri, isPublicationIdVersionFormat, isPublishedArtifactUri } from "./Utils";

// Session caches
const CACHE = new BackendCache();

/**
 * Retrieve stack information by its identifier.
 * @param {string} stackId 
 * @returns {Promise}
 */
export const getStack = stackId => {
    if (!stackId.startsWith('@')) stackId = `@default/${stackId}`;
    return _getStack(stackId);
}

/**
 * Create a new build version for a stack.
 * @param {string} stackId 
 * @param {object} patch 
 * @returns {Promise}
 */
export const createStackBuild = (stackId, patch) => {
    if (!stackId.startsWith('@')) stackId = `@default/${stackId}`;
    return _createStackBuild(stackId, patch);
}

/**
 * Retrieve a stack's publication. Results are cached since publications do not change.
 * @param {string} stackId 
 * @param {string} publicationId 
 * @returns {Promise}
 */
export const getStackPublication = (stackId, publicationId) => {
    if (!stackId.startsWith('@')) stackId = `@default/${stackId}`;
    const uri = `${stackId}/${publicationId}`;
    const cacheKey = `stackPublications/${uri}`;
    const cachedValue = localStringCache.get(cacheKey);

    if (cachedValue) return Promise.resolve(JSON.parse(cachedValue));

    if (!CACHE.PUBLICATIONS_PROMISES.hasOwnProperty(uri)) {
        CACHE.PUBLICATIONS_PROMISES[uri] = _getStackPublication(stackId, publicationId).then(publication => {
            localStringCache.set(cacheKey, JSON.stringify(publication));
            return publication;
        });
    }

    return CACHE.PUBLICATIONS_PROMISES[uri];
}

/**
 * Return the artifact uri
 * my-stack:v1 => @default/my-stack:e3f3867065e6e03fe97b66c0980610a023db7568 
 * my-stack:e3f3867065e6e03fe97b66c0980610a023db7568 => @default/my-stack:e3f3867065e6e03fe97b66c0980610a023db7568 
 * @org/my-stack:e3f3867065e6e03fe97b66c0980610a023db7568 => @org/my-stack:e3f3867065e6e03fe97b66c0980610a023db7568 
 * ...
 * Note: we cache also the latest to have the same version for all resources in the current session. 
 * @param {*} uri       
 * @returns the the artifact uri
 */
export const getStackArtifactBuildUri = uri => {
    let [resourceId, versionId] = uri.split(':');
    if (!resourceId.startsWith('@')) resourceId = `@default/${resourceId}`;
    uri = `${resourceId}:${versionId}`;
    if (!CACHE.BUILD_URIS_PROMISES.hasOwnProperty(uri)) {
        let promise;
        if (isPublishedArtifactUri(uri)) {
            promise = getStackPublication(resourceId, versionId)
                .then(publication => `${resourceId}:${publication.buildId}`);
        } else if (isBuiltArtifactUri(uri)) {
            promise = Promise.resolve(uri);
        } else {
            throw new Error(`Stack uri is not valid. Got ${uri}`);
        }
        CACHE.BUILD_URIS_PROMISES[uri] = promise;
    }
    return CACHE.BUILD_URIS_PROMISES[uri];
}

/**
 * Download a file from a stack.
 * @param {string} uri 
 * @param {string} filepath 
 * @returns {Promise<string>}
 */
export const downloadStackFile = (uri, filepath) => {
    return getStackArtifactBuildUri(uri).then(uri => {
        const [artifactId, buildId] = uri.split(':');
        const cacheKey = `resources/${artifactId}/${buildId}.zip${filepath}`;
        const cachedValue = localStringCache.get(cacheKey);

        if (cachedValue) return cachedValue;

        if (!CACHE.DOWNLOAD_FILES_PROMISES.hasOwnProperty(cacheKey)) {
            CACHE.DOWNLOAD_FILES_PROMISES[cacheKey] = _downloadStackFile(artifactId, buildId, filepath).then(str => {
                localStringCache.set(cacheKey, str);
                return str;
            });
        }
        return CACHE.DOWNLOAD_FILES_PROMISES[cacheKey];
    });
}

/**
 * Retrieve files from a folder within a stack.
 * @param {string} uri 
 * @param {string} folderPath 
 * @returns {Promise<object>}
 */
export const getStackFiles = (uri, folder) => {
    return getStackArtifactBuildUri(uri).then(uri => {
        const [artifactId, buildId] = uri.split(':');
        const cacheKey = `resources/${artifactId}/${buildId}.zip${folder}`;
        const cachedValue = localStringCache.get(cacheKey);

        if (cachedValue) return JSON.parse(cachedValue);

        if (!CACHE.DOWNLOAD_FOLDERS_PROMISES.hasOwnProperty(cacheKey)) {
            CACHE.DOWNLOAD_FOLDERS_PROMISES[cacheKey] = _getStackFiles(artifactId, buildId, folder).then(json => {
                localStringCache.set(cacheKey, JSON.stringify(json));
                return json;
            });
        }

        return CACHE.DOWNLOAD_FOLDERS_PROMISES[cacheKey];
    });
}