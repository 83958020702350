import { shikiToMonaco } from '@shikijs/monaco';
import * as monaco from 'monaco-editor-core';
import React from 'react';
import { createHighlighter } from 'shiki';
import ArtifactBrowserContext from '../browser/ArtifactBrowserContext';
import { chain, withContext } from '../utils/WithContext';
import ArtifactEditorContext from './ArtifactEditorContext';


// Create the highlighter, it can be reused
const highlighter = await createHighlighter({
    themes: [
        'nord',
        'slack-ochin',
        'min-light',
        'light-plus',
    ],
    langs: [
        'javascript',
        'typescript',
        'vue'
    ],
})

// Register the languageIds first. Only registered languages will be highlighted.
monaco.languages.register({ id: 'vue' })
monaco.languages.register({ id: 'typescript' })
monaco.languages.register({ id: 'javascript' })

// Register the themes from Shiki, and provide syntax highlighting for Monaco.
shikiToMonaco(highlighter, monaco);



const CodeEditorFile = chain(
    withContext(ArtifactEditorContext)(({ artifact }) => ({ artifact })),
    withContext(ArtifactBrowserContext)(({ selectedPath }) => ({ selectedPath })),
)(class extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: null,
        }
        // the file change listener to update the state if the file is updated outside this component.      
        this.fileChangeListener = event => {
            this.setState({
                value: event.value,
            });
        }

        this.editor = null;
        this.shiki = React.createRef()
    }

    componentDidMount() {
        this.props.artifact.getFile(this.props.filepath).then(value => {
            this.setState({
                value,
            });

            this.editor = monaco.editor.create(this.shiki.current, {
                value: this.state.value,
                fontSize: 12,
                language: 'javascript',
                theme: 'light-plus',
                contextmenu: false,
                minimap: { enabled: false },
                stickyScroll: { enabled: false },
                //bracketPairColorization: { enabled: true },
            })
            this.editor.onDidChangeModelContent(() => {
                this.props.artifact.editFile(this.props.filepath, this.editor.getValue());
            });

        });

        this.props.artifact.addFileChangeListener(this.props.filepath, this.fileChangeListener);
    }


    componentDidUpdate(oldProps, oldState) {
        if (!this.editor) return;
        const model = this.editor.getModel();
        if (this.editor && this.state.value !== model.getValue()) {
            model.setValue(this.state.value || "loading");
        }
    }


    componentWillUnmount() {
        this.props.artifact.removeFileChangeListener(this.props.filepath, this.fileChangeListener);
    }


    render() {

        return <div ref={this.shiki} 
        style={{
            width: '100%',
            height: '100%',
        }}

        />

    }

});

export default CodeEditorFile;