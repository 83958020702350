/**
 * 
 */
export default class BackendCache {
    constructor() {
        this.PUBLICATIONS_PROMISES = {};
        this.BUILD_URIS_PROMISES = {};
        this.DOWNLOAD_FILES_PROMISES = {};
        this.DOWNLOAD_FOLDERS_PROMISES = {};
    }
}

