/**
 * Checks if the given string is in the build ID version format.
 * The format is expected to start with 'b', followed by 8 digits, 
 * a hyphen, and 3 lowercase letters.
 * 
 * Example format: `b20241018-abc`
 * 
 * @param {string} str - The string to be checked.
 * @returns {boolean} - Returns true if the string matches the build ID version format.
 */
export const isBuildIdVersionFormat = str => /^b[0-9]{8}-[a-z]{3}$/.test(str);

/**
 * Checks if the given string is in the publication ID version format.
 * The format is expected to start with 'v', followed by a number without 
 * leading zeroes (1-9) and any number of digits.
 * 
 * Example format: `v1`, `v123`
 * 
 * @param {string} str - The string to be checked.
 * @returns {boolean} - Returns true if the string matches the publication ID version format.
 */
export const isPublicationIdVersionFormat = str => /^v[1-9][0-9]*$/.test(str);

/**
 * Checks if the given string is in the artifact version format.
 * The format is expected to be exactly 40 hexadecimal characters 
 * (0-9, a-f).
 * 
 * Example format: `0123456789abcdef0123456789abcdef01234567`
 * 
 * @param {string} str - The string to be checked.
 * @returns {boolean} - Returns true if the string matches the artifact version format.
 */
export const isArtifatVersionFormat = str => /^[0-9a-f]{40}$/.test(str);

/**
 * Checks if the given URI represents a published artifact.
 *
 * A published artifact URI is expected to potentially contain a version ID 
 * following the ':' character. The function validates that the versionId 
 * exists and follows the correct publication ID version format.
 * 
 * Example format: `some-uri:v1`
 *
 * @param {string} uri - The URI to check for being a published artifact.
 * @returns {boolean} - Returns true if the URI is a published artifact with a valid version ID, false otherwise.
 */
export const isPublishedArtifactUri = uri => {
    let [, versionId] = uri.split(':');
    return versionId && isPublicationIdVersionFormat(versionId);
};

/**
 * Checks if the given URI follows the built artifact format.
 * The URI is expected to have a version ID that matches the build ID version format.
 * 
 * Example format: `some-uri:b20241018-abc`
 * 
 * @param {string} uri - The URI to be checked.
 * @returns {boolean} - Returns true if the URI matches the built artifact format.
 */
export const isBuiltArtifactUri = uri => {
    const [, versionId] = uri.split(':');
    return versionId && isBuildIdVersionFormat(versionId.toLowerCase());
};