
import * as React from 'react';
import { getAppState } from '../backend/Api.js';
import { getDatabase } from "../Firebase.js";
import AppContext from './AppContext.js';

const POOLING_DELAY = 10000;
/**
 * 
 */
class AppProvider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            app: null,
            state: null,
        }
        this.deploymentId = null;
    }

    componentDidMount() {
        const appDatabaseId = encodeURIComponent(this.props.appId);
        this.unsubscribeAppListener = getDatabase().collection('apps').doc(appDatabaseId).onSnapshot(doc => {
            const app = doc.data();
            this.setState({ app });
            if (app.deploymentId !== this.deploymentId) {
                this.deploymentId = app.deploymentId;
                this.updateAppState();
            }
        });
    }

    updateAppState = () => {
        return getAppState(this.props.appId).then(state => {
            const { status, deploymentId, stackId, buildId } = state;
            this.setState({ state });
            if ((status === 'DEPLOYING') || (deploymentId !== this.deploymentId)) {
                setTimeout(() => {
                    this.updateAppState();
                }, POOLING_DELAY);
            }
        });
    }

    /**
     * 
     * @returns 
     */
    render() {
        return <AppContext.Provider value={{
            appId: this.props.appId,
            app: this.state.app,
            state: this.state.state,
        }}>
            {this.props.children}
        </AppContext.Provider>
    }
};

export default AppProvider;
