import { mdiFormatText } from '@mdi/js';
import React from 'react';
import IconButton from '../../design/IconButton.js';
import { ListItem } from '../../design/ListItem.js';
import TextPrimaryButton from '../../design/TextPrimaryButton.js';
import TitleBox from '../../design/TitleBox.js';
import { iconPlus } from '../../icons/LightIcons.js';
import PopupContext from '../../popup/PopupContext.js';
import ResourceContext from '../../stack/ResourceContext.js';
import { chain, withContext } from '../../utils/WithContext.js';


const ResourceDependenciesManager = chain(
    withContext(PopupContext)(({ open }) => ({ open })),
    withContext(ResourceContext)(({ component, removeDependency }) => ({ component, removeDependency })),
)(class extends React.Component {

    constructor(props) {
        super(props);
    }

    handleAddDependency = () => {
        this.props.open(<CreateDependency />)
    }

    handleCloseAddDependency = () => {
        this.props.close();
    }

    render() {
        return (
            <TitleBox
                title={'Dependencies'}
                buttons={<>
                    <IconButton path={iconPlus} onClick={this.handleAddDependency} />
                </>}>
                {Object.entries(this.props.component.dependencies || {}).map(([dependencyName, Dependency]) => (
                    <ListItem
                        name={dependencyName}
                        icon={mdiFormatText}
                        onDelete={() => this.props.removeDependency(dependencyName)} />
                ))}
            </TitleBox>
        );
    }
});


const CreateDependency = chain(
    withContext(PopupContext)(({ close }) => ({ close })),
    withContext(ResourceContext)(({ addDependency }) => ({ addDependency })),
)(class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dependencyName: null,
            service: null,
            role: null,
        }
    }

    handleChange = key => event => {
        console.log(event.target.value)
        this.setState({
            [key]: event.target.value,
        })
    }
    handleCreate = () => {
        this.props.addDependency(this.state.dependencyName, {
            service: this.state.service,
            role: this.state.role,
        });
        this.props.close();
    }

    render() {
        return (
            <div style={{ width: '600px', padding: '20px', display: 'flex', flexDirection: 'column', gap: '8px' }}>

                <span style={{ fontSize: '13px', fontWeight: 500 }}>
                    Add resource dependency to this component
                </span>

                <input type={"text"} onChange={this.handleChange('dependencyName')} />
                <input type={"text"} onChange={this.handleChange('service')} />
                <input type={"text"} onChange={this.handleChange('role')} />

                <TextPrimaryButton text={'add'} onClick={this.handleCreate} />
            </div>
        )
    }

})

export default ResourceDependenciesManager;