import { mdiChevronDown, mdiChevronRight } from '@mdi/js';
import Icon from '@mdi/react';
import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

const Header = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    user-select: none;
    cursor: default;
    padding-left: 8px;
`;

const TitleContainer = styled.div`
    flex-grow: 1;
    height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    gap: 2px;
    align-items: center;
`;

const Title = styled.span`
    font-weight: 400;
`;

const ButtonContainer = styled.div`
    flex-shrink: 0;
    display: flex;
    gap: 2px;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    //min-height: 24px;
`;

export default class TitleBox extends React.Component {

    render() {
        return (
            <Container>
                <Header>
                    <TitleContainer>
                        <Title>{this.props.title}</Title>
                    </TitleContainer>
                    <ButtonContainer>{this.props.buttons}</ButtonContainer>
                </Header>
                <Content>{this.props.children}</Content>
            </Container>
        );
    }
}