import React from 'react';
import { createStack } from '../backend/Api.js';
import Button from '../system/Button.js';
import FormElement from '../system/FormElement.js';
import Input from '../system/Input.js';
import Title from '../system/Title.js';

class CreateStack extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            creating: false
        }
        this.values = {};
    }

    onChange = name => event => this.values[name] = event.target.value;

    setCreating = creating => this.setState({ creating })

    create() {
        this.setCreating(true);
        createStack({
            organization: 'default',
            stackName: this.values.name,
        }).then(stack => {
            this.setCreating(false);
            this.props.navigate(`/stacks/${stack.stackId}`);
        }).catch(err => {
            console.err(err); // TODO
            this.setCreating(false);
        });
    }

    render() {
        return (
            <div sx={{
                display: 'flex',

                margin: 'auto',
                gap: '20px',
                width: 400,
            }}>
                <Title>Create a new stack</Title>
                <FormElement name="Stack name">
                    <Input sx={{ width: '100%' }} readOnly={this.state.creating} onChange={this.onChange('name').bind(this)} />
                </FormElement>
                <FormElement name="Description">
                    <Input sx={{ width: '100%' }} readOnly={this.state.creating} onChange={this.onChange('description').bind(this)} />
                </FormElement>
                <Button onClick={this.create.bind(this)}>Create new stack</Button>
            </div>
        );
    }
}

export default CreateStack;