import * as React from 'react';
import StackContext from '../../stack/StackContext.js';
import { chain, withContext } from '../../utils/WithContext.js';
import StackPageContext from './StackPageContext.js';


const StackPageProvider = chain(
    withContext(StackContext)(({ stack, openResource, closeResource, openedResourceIds }) => ({ stack, openResource, closeResource, openedResourceIds })),
)(
    class extends React.Component {

        constructor(props) {
            super(props);
            this.state = {
                openedPageResourceIds: []
            };
        }

        componentDidUpdate(oldProps) {
            //const expanded = this.props.resourceIds.filter(id => !oldProps.resourceIds.includes(id));
            const closedResources = oldProps.openedResourceIds.filter(id => !this.props.openedResourceIds.includes(id))
            // close unloaded resource 
            closedResources.forEach(resourceId => {
                this.closePageResource(resourceId);
            });
        }

        openPageResource = resourceId => {
            this.props.openResource(resourceId);
            const resourceIds = [...this.state.openedPageResourceIds];
            const index = resourceIds.indexOf(resourceId);
            if (index !== -1) {
                resourceIds.splice(index, 1);
            }
            resourceIds.unshift(resourceId); // add in first place
            this.setState({ openedPageResourceIds: resourceIds });
            
        }

        closePageResource = resourceId => {
            this.setState(prevState => {
                if (!prevState.openedPageResourceIds.includes(resourceId)) return null;
                const openedPageResourceIds = prevState.openedPageResourceIds.filter(id => id !== resourceId);
                return { openedPageResourceIds }
            });
            // check the resource has no child, if yes we can collapse it
            const children = this.state.openedPageResourceIds.filter(id => id.startsWith(`${resourceId}/`));
            if (children.length === 0) {
                this.props.closeResource(resourceId);
            }
        }

        getOpenedResourceId = () => {
            return this.state.openedPageResourceIds[0];
        }

        render() {
            if (!this.props.stack) return <>Loading</>;
            return (
                <StackPageContext.Provider value={{
                    openedPageResourceIds: this.state.openedPageResourceIds,
                    getOpenedResourceId: this.getOpenedResourceId,
                    openPageResource: this.openPageResource,
                    closePageResource: this.closePageResource,
                }}>
                    {this.props.children}
                </StackPageContext.Provider>
            );
        }
    })

export default StackPageProvider;