import React from 'react';
import ArtifactEditorContext from './ArtifactEditorContext';

class ArtifactEditorProvider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filepath: null,
            filepaths: [],
            filepathsOrdered: [], // TODO: can be out of state ?
        }
    }

    /**
     * 
     * @param {*} filepath 
     */
    openFile = filepath => {
        if (!filepath) debugger;
        let state = {};
        const filepaths = this.state.filepaths;
        if (!filepaths.includes(filepath)) {
            filepaths.push(filepath);
            state.filepaths = filepaths;
        }
        // put the select order
        const filepathsOrdered = this.state.filepathsOrdered;
        // delete previous occurence if exists
        const indexOrdered = filepathsOrdered.indexOf(filepath);
        if (indexOrdered != 0) {
            if (indexOrdered > 0) filepathsOrdered.splice(indexOrdered, 1);
            // set it at the beginning
            filepathsOrdered.unshift(filepath); // add at the beginning of the select order
            state.filepathsOrdered = filepathsOrdered;
            state.filepath = filepathsOrdered[0];
        }
        // update the state.
        if (Object.keys(state).length > 0) {
            this.setState(state);
        }
    }

    /**
     * 
     * @param {*} filepath 
     */
    closeFile = filepath => {
        const filepaths = this.state.filepaths;
        const filepathsOrdered = this.state.filepathsOrdered;
        // delete from the list of files opened
        const index = filepaths.indexOf(filepath);
        if (index > -1) filepaths.splice(index, 1);
        // delete from the ordered list
        const indexOrdered = filepathsOrdered.indexOf(filepath);
        if (indexOrdered > -1) filepathsOrdered.splice(indexOrdered, 1);
        // update the state.
        this.setState({
            filepath: filepathsOrdered[0],
            filepaths,
            filepathsOrdered,
        });
    }

    /**
     *
     * @returns 
     */
    render() {
        return <ArtifactEditorContext.Provider value={{
            artifact: this.props.artifact,
            filepath: this.state.filepath,
            filepaths: this.state.filepaths,
            openFile: this.openFile.bind(this),
            closeFile: this.closeFile.bind(this),
        }}>
            {this.props.children}
        </ArtifactEditorContext.Provider>
    }
}

export default ArtifactEditorProvider;