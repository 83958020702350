import React from 'react';
import ArtifactBrowserContext from './ArtifactBrowserContext';
import ArtifactBrowserEvents from './ArtifactBrowserEvents';

class ArtifactBrowserProvider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPath: null,
            expandedFolders: ['/'],
            addElement: null,
        };
        this.listeners = [];
    }

    addEventListener = cb => {
        if (!this.listeners.includes(cb)) {
            this.listeners.push(cb);
        }
    }

    removeEventListener = cb => {
        var index = this.listeners.indexOf(cb);
        if (index !== -1) {
            this.listeners.splice(index, 1);
        }
    }

    onFileTreeChange = (event, path) => {
        this.listeners.forEach(cb => cb(event, path));
    }

    isExpanded = folderPath => this.state.expandedFolders.includes(folderPath);

    expand = folderPath => {
        if (!this.state.expandedFolders.includes(folderPath)) {
            this.setState({ expandedFolders: [...this.state.expandedFolders, folderPath] });
        }
    }

    collapse = folderPath => {
        if (this.state.expandedFolders.includes(folderPath)) {
            this.setState({ expandedFolders: this.state.expandedFolders.filter(f => f !== folderPath) });
        }
    }

    getSelected = () => {
        return this.state.selectedPath
    }

    getFolderPath = path => {
        return path.substring(0, path.lastIndexOf('/') + 1);
    }

    setSelected = selectedPath => {
        this.setState({ selectedPath });
        // raise event if 
        if (selectedPath) {
            if (selectedPath.endsWith('/')) {
                this.onFileTreeChange(ArtifactBrowserEvents.FOLDER_SELECTED, selectedPath);
            } else {
                this.onFileTreeChange(ArtifactBrowserEvents.FILE_SELECTED, selectedPath);
            }
        } else {
            // unselect not implemented yet.
        }
    }

    isSelected = path => {
        return this.state.selectedPath === path;
    }

    addFolder = () => {
        const path = this.state.selectedPath ? this.getFolderPath(this.state.selectedPath) : '/';
        this.addFolderAt(path);
    }

    addFile = () => {
        const path = this.state.selectedPath ? this.getFolderPath(this.state.selectedPath) : '/';
        this.addFileAt(path);
    }

    addFolderAt = path => {
        if (!path.endsWith('/')) throw new Error('Path must be a folder path. ie ends with a /')
        this.setState({ addFolderOrFile: ['folder', path] });
        this.expand(path);
    }

    addFileAt = path => {
        if (!path.endsWith('/')) throw new Error('Path must be a folder path. ie ends with a /')
        this.setState({ addFolderOrFile: ['file', path] });
        this.expand(path);
    }

    isAddFolder = path => {
        if (!this.state.addFolderOrFile) return false;
        const [t, p] = this.state.addFolderOrFile;
        return t === 'folder' && p === path;
    }

    isAddFile = path => {
        if (!this.state.addFolderOrFile) return false;
        const [t, p] = this.state.addFolderOrFile;
        return t === 'file' && p === path;
    }

    clearAddFileOrFolder = () => {
        this.setState({ addFolderOrFile: null });
    }

    render() {
        return <ArtifactBrowserContext.Provider value={{
            // Public methods
            artifact: this.props.artifact,
            selectedPath: this.state.selectedPath,
            addEventListener: this.addEventListener.bind(this),
            removeEventListener: this.removeEventListener.bind(this),
            isExpanded: this.isExpanded.bind(this),
            expand: this.expand.bind(this),
            collapse: this.collapse.bind(this),
            getFolderPath: this.getFolderPath.bind(this),
            //getSelected: this.getSelected.bind(this),
            setSelected: this.setSelected.bind(this),
            isSelected: this.isSelected.bind(this),
            addFolder: this.addFolder.bind(this),
            addFile: this.addFile.bind(this),
            addFolderAt: this.addFolderAt.bind(this),
            addFileAt: this.addFileAt.bind(this),
            clearAddFileOrFolder: this.clearAddFileOrFolder.bind(this),
            isAddFolder: this.isAddFolder.bind(this),
            isAddFile: this.isAddFile.bind(this),
            onFileTreeChange: this.onFileTreeChange.bind(this),
        }}>
            {this.props.children}
        </ArtifactBrowserContext.Provider>
    }
}

export default ArtifactBrowserProvider; 