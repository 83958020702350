import React from 'react';
import { chain, withContext } from '../utils/WithContext';
import ArtifactEditorContext from './ArtifactEditorContext';
import Module from './module/Module';

const ModuleEditorFile = chain(
    withContext(ArtifactEditorContext)(({ artifact }) => ({ artifact })),
)(class extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            module: null,
        }
        // the file change listener to update the state if the file is updated outside this component.      
        this.fileChangeListener = event => {
            try {
                this.setState({
                    module: JSON.parse(event.value),
                });
            } catch(err) {
                console.log(err);
            }
        }
    }

    componentDidMount() {
        this.props.artifact.getFileAsJson(this.props.filepath)
        .then(module => {
            this.setState({
                loading: false,
                module,
            });
        })
        .catch(err => console.log(err))
        this.props.artifact.addFileChangeListener(this.props.filepath, this.fileChangeListener);
    }

    componentWillUnmount() {
        this.props.artifact.removeFileChangeListener(this.props.filepath, this.fileChangeListener);
    }

    onChange = value => {
        const jsonString = JSON.stringify(value, null, 2);
        //console.log('Change module value ', jsonString);
        this.props.artifact.editFile(this.props.filepath, jsonString);
    }

    render() {
        if (this.state.loading) return <>Loading</>;
        return <Module
            module={this.state.module}
            onChange={this.onChange.bind(this)}
        />
    }

});

export default ModuleEditorFile;