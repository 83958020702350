import Icon from '@mdi/react';
import React from 'react';
import styled from 'styled-components';


const Container = styled.div`
    width: ${(props) => props.small ? '22px' : '42px'};
    height: ${(props) => props.small ? '22px' : '42px'};
    background: ${(props) => props.color || '#000'};
    border: 1px solid #0000000f;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
`;



export const ResourceIcon = ({ path, color, small }) => {
    return (
        <Container small color={color}>
            <Icon path={path} size={small ? 0.8 : 1} />
        </Container>

    );
};