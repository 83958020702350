import { mdiFileMultipleOutline, mdiSquareOutline } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import ArtifactBrowser from '../../browser/ArtifactBrowser.js';
import CollapsingBox from '../../design/CollapsingBox.js';
import IconButton from '../../design/IconButton.js';
import { iconDotsVertical } from '../../icons/LightIcons.js';
import ResourceContext from '../../stack/ResourceContext.js';
import StackContext from '../../stack/StackContext.js';
import { chain, withContext } from '../../utils/WithContext.js';
import ComponentsDropDown from './ComponentsDropDown.js';
import ResourceDependenciesManager from './ResourceDependenciesManager.js';
import ResourceVariablesManager from './ResourceVariablesManager.js';
import TitleBox from '../../design/TitleBox.js';
import PopupContext from '../../popup/PopupContext.js';


const ComponentPanel = chain(
    withContext(StackContext)(({ stack, artifacts }) => ({ stack, artifacts })),
    withContext(ResourceContext)(({ resourceId, service, component, addVariable, removeVariable, addDependency, removeDependency }) => ({ resourceId, service, component, addVariable, removeVariable, addDependency, removeDependency })),
)(class extends React.Component {


    render() {

        const parts = this.props.resourceId.split('/');
        const name = parts[parts.length - 1];
        const serviceName = this.props.component.service || 'modules';
        const icon = this.props.service ? this.props.service.icon : mdiSquareOutline;
        const color = this.props.service ? this.props.service.color : '#000';

        return (
            <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
            }}>



                    <div style={{
                        //padding: '10px',//'8px',
                        padding: '6px',
                        paddingBottom: '20px',
                        //background: '#09faf9', //#e7ecee',
                        //borderRadius: '4px',
                        borderBottom: '1px solid rgba(0,0,0,.05)',
                        display: 'flex',
                        gap: '8px',
                        alignItems: 'center',
                    }}>

                        <div style={{ width: '38px', height: '38px ', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '3px', color: 'white', backgroundColor: color}}>
                        <Icon path={icon} size={1} />
                        </div>

                        <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: '2px' }}>
                            <div style={{ fontWeight: 400, fontSize: 14 }}>{name}</div>
                            <div style={{ fontWeight: 300, color: '#9ca3af' }}>{serviceName}</div>
                        </div>
                    </div>

                    {true && <TitleBox
                                    path={mdiFileMultipleOutline}

                                    title={'Files'}
                                    buttons={<>
                                        <IconButton light small path={'M12,16A1.5,1.5 0 0,1 13.5,17.5A1.5,1.5 0 0,1 12,19A1.5,1.5 0 0,1 10.5,17.5A1.5,1.5 0 0,1 12,16M12,10A1.5,1.5 0 0,1 13.5,11.5A1.5,1.5 0 0,1 12,13A1.5,1.5 0 0,1 10.5,11.5A1.5,1.5 0 0,1 12,10M12,4A1.5,1.5 0 0,1 13.5,5.5A1.5,1.5 0 0,1 12,7A1.5,1.5 0 0,1 10.5,5.5A1.5,1.5 0 0,1 12,4Z'} onClick={this.props.addFile} />
                                    </>}>

                                    <ArtifactBrowser />

                                </TitleBox>}

                    <ResourceDependenciesManager />

                    <ResourceVariablesManager />



            </div>


        );
    }
});



export default ComponentPanel;


//                                fontFamily: 'IBM Plex Mono,ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace'
