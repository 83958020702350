import React from 'react';
import { chain, withContext } from '../utils/WithContext';
import InfiniteContext from './InfiniteContext';
import InfiniteGestureListener from './InfiniteGestureListener';
import InfiniteNodeContext from './InfiniteNodeContext';

const InfiniteNode = chain(
    withContext(InfiniteContext)(({
        addNodeElement,
        removeNodeElement,
        selectingNodes,
        selectedNodes,
        offsets,
        moveNodes,
        nodes,
        grid
    }, props) =>
    ({
        addNodeElement,
        removeNodeElement,
        selecting: selectingNodes.includes(props.nodeId),
        selected: selectedNodes.includes(props.nodeId),
        moveNodes,
        node: nodes[props.nodeId],
        offset: offsets[props.nodeId],
        grid,
    })),
)(class extends React.Component {

    constructor(props) {
        super(props);
        this.element = React.createRef();
    }

    componentDidMount() {
        this.props.addNodeElement(this.props.nodeId, this.element.current);
        // create gesture listeners
        this.gesture = new InfiniteGestureListener(this.element.current);
        this.gesture.listenMouseEvents();
        this.setElementDragGestureListener();
        // call the componentDidMountOrUpdate function
        this.componentDidMountOrUpdate();
    }

    componentDidUpdate() {
        this.componentDidMountOrUpdate();
    }

    componentDidMountOrUpdate() {
        this.setElementStyle();
    }

    componentWillUnmount() {
        this.gesture.unlistenMouseEvents();
        this.props.removeNodeElement(this.props.nodeId);
    }

    /**
     * 
     */
    setElementStyle = () => {
        const s = this.element.current.style;
        s.left = (this.props.node.x + this.props.offset.x) + 'px';
        s.top = (this.props.node.y + this.props.offset.y) + 'px';
    }

    /**
     * 
     */
    setElementDragGestureListener() {
        let nodeStartPosition = {};
        this.gesture
            .when(({ event }) => event.which == 1 && !this.props.selected)
            .onStart(() => {
                nodeStartPosition = {
                    x: this.props.node.x,
                    y: this.props.node.y,
                }
            })
            .onMove(({ delta }) => {
                this.props.moveNodes({
                    [this.props.nodeId]: {
                        x: Math.round((nodeStartPosition.x + delta.x) / this.props.grid.x) * this.props.grid.x,
                        y: Math.round((nodeStartPosition.y + delta.y) / this.props.grid.y) * this.props.grid.y,
                    }
                });
            })
            .onStop(({ delta }) => {
                this.props.moveNodes({
                    [this.props.nodeId]: {
                        x: Math.round((nodeStartPosition.x + delta.x) / this.props.grid.x) * this.props.grid.x,
                        y: Math.round((nodeStartPosition.y + delta.y) / this.props.grid.y) * this.props.grid.y,
                    }
                });
            });
    }

    render() {
        return <div
            ref={this.element}
            style={{
                position: 'absolute',
                transform: 'translate(-50%, -50%)', // center the element
            }}>
            <InfiniteNodeContext.Provider value={{
                selected: this.props.selected,
                selecting: this.props.selecting,
            }}>
                {this.props.children}
            </InfiniteNodeContext.Provider>
        </div>
    }

});

export default InfiniteNode;