import styled from 'styled-components';

const Section = styled.div`
    display: flex;
    align-items: center;
    gap: ${(props) => props.gap || '0px'};
    height: 100%;
    ${(props) => props.justify && `justify-content: ${props.justify};`}
    ${(props) => props.flex && `flex: ${props.flex};`}
`;

export default Section;