import { mdiArrowRight } from '@mdi/js';
import Icon from '@mdi/react';
import PropTypes from 'prop-types'; // For prop validation
import React from 'react';

/**
 * A reusable button component with text and an arrow icon.
 * - Customizable via props for text and click handler.
 * - Styled using Material-UI's `sx` prop.
 */
const TextPrimaryButton = ({ text, onClick }) => {
    return (
        <div
            // Click handler passed via props
            onClick={onClick}
            style={{
                height: 26,
                padding: '0 8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '4px',
                borderRadius: '3px',
                fontWeight: 400,
                color: '#FFF',
                backgroundColor: '#1E1E1E',
                backgroundImage: 'linear-gradient(rgb(31, 41, 55), rgb(0, 0, 0))',
                transition: 'background-color .1s',
                cursor: 'default',
                '&:hover': {
                    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 2px 4px inset',
                },
            }}
        >
            {/* Text displayed in the button */}
            {text}

            {/* Arrow icon displayed on the right */}
            <Icon path={mdiArrowRight} size={0.7} />
        </div>
    );
};

// Prop validation for better developer experience
TextPrimaryButton.propTypes = {
    text: PropTypes.string.isRequired, // Button label text
    onClick: PropTypes.func.isRequired, // Click handler function
};

export default TextPrimaryButton;