import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { configure } from "./Firebase.js";
import AuthenticationContext from './authentication/AuthenticationContext.js';
import AuthenticationProvider from './authentication/AuthenticationProvider.js';
import ErrorHandler from './components/ErrorHandler.js';
import './index.css';
import CreateApp from './pages/CreateApp.js';
import CreateHost from './pages/CreateHost.js';
import CreateStack from "./pages/CreateStack.js";
import SignIn from "./pages/SignIn.js";
import StackPage from './pages/stack/StackPage.js';
import StackPageProvider from './pages/stack/StackPageProvider.js';
import StackProvider from './stack/StackProvider.js';
import AppProvider from './app/AppProvider.js';
import AppPageProvider from './pages/app/AppPageProvider.js';
import AppPage from './pages/app/AppPage.js';

//import './roboto.css';

//const PROJECT_ID = 'blueforge-322008'; // TODO: should not be written in the front source code.
const FIREBASE_CONFIG_FILE_URL = 'https://www.blueforge.cloud/firebase-config.json'


/*const theme = createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    fontFamily: '"Source Sans Pro", ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";',
                },
            }
        }
    }
});*/

// load backend configuration
configure(FIREBASE_CONFIG_FILE_URL).then(() => {
    const root = createRoot(document.getElementById('root'));
    root.render(
        <ErrorHandler>
            <BrowserRouter>
                <Routes>
                    <Route path="/">
                        <Route path="/" element={<AuthenticationProvider />}>
                            <Route path="new">
                                <Route path="stack" element={<RouteCreateStack />} />
                                <Route path="host" element={<RouteCreateHost />} />
                                <Route path="app" element={<RouteCreateApp />} />
                            </Route>
                            <Route path="/stack">
                                <Route path=":organization">
                                    <Route path=":stackName" element={<RouteStack />} />
                                </Route>
                            </Route>
                            <Route path="/app">
                                <Route path=":organization">
                                    <Route path=":appName" element={<RouteApp />} />
                                </Route>
                            </Route>
                        </Route>
                    </Route>
                    <Route path="/signin" element={<RouteSignIn />} />
                </Routes>
            </BrowserRouter>
        </ErrorHandler>
    );
});

const RouteStack = props => {
    const params = useParams();
    return (
        <AuthenticationContext.Consumer>
            {({ user }) => (
                <StackProvider
                    userId={user.uid}
                    stackId={`@${params.organization}/${params.stackName}`}>
                    <StackPageProvider>
                        <StackPage />
                    </StackPageProvider>
                </StackProvider>
            )}
        </AuthenticationContext.Consumer>

    );
};

const RouteApp = props => {
    const params = useParams();
    return (
        <AuthenticationContext.Consumer>
            {({ user }) => (
                <AppProvider
                    userId={user.uid}
                    appId={`@${params.organization}/${params.appName}`}>
                    <AppPageProvider>
                        <AppPage />
                    </AppPageProvider>
                </AppProvider>
            )}
        </AuthenticationContext.Consumer>
    );
};

const RouteSignIn = props => {
    const navigate = useNavigate();
    return (
        <SignIn
            navigate={navigate}
            {...props}
        />
    );
};

const RouteCreateStack = props => {
    const navigate = useNavigate();
    return (
        <CreateStack
            navigate={navigate}
            {...props}
        />
    );
};

const RouteCreateHost = props => {
    const navigate = useNavigate();
    return (
        <CreateHost
            navigate={navigate}
            {...props}
        />
    );
};

const RouteCreateApp = props => {
    const navigate = useNavigate();
    return (
        <CreateApp
            navigate={navigate}
            {...props}
        />
    );
};