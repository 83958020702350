import React from 'react';
import { chain, withContext } from '../utils/WithContext';
import InfiniteContext from './InfiniteContext';
import InfiniteGestureListener from './InfiniteGestureListener';
import { getEncapsulatedRect } from './InfiniteProvider';

const InfiniteSelected = chain(
    withContext(InfiniteContext)(({ selectedNodes, nodes, sizes, elements, delta, container, moveNodes, grid }) =>
        ({ selectedNodes, nodes, sizes, elements, delta, container, moveNodes, grid })),
)(class extends React.Component {

    constructor(props) {
        super(props);
        this.selectedRect = null; // computed at render() time
        this.nodeRectCoodinates = {}
        this.element = React.createRef();
    }

    componentDidMount() {
        // create gesture listeners
        this.gesture = new InfiniteGestureListener(this.element.current);
        this.gesture.listenMouseEvents();
        this.setElementDragGestureListener();
        this.componentDidMountOrUpdate();
    }

    componentDidUpdate(oldProps) {
        this.componentDidMountOrUpdate(oldProps);
    }

    componentDidMountOrUpdate(oldProps) {
        const oldSelectedNodes = oldProps ? oldProps.selectedNodes : [];
        const newSelectedNodes = this.props.selectedNodes;
        const oldNodes = oldSelectedNodes.filter(nodeId => !newSelectedNodes.includes(nodeId));
        const newNodes = newSelectedNodes.filter(nodeId => !oldSelectedNodes.includes(nodeId));
        // move element inside this 
        newNodes.forEach(nodeId => this.moveElementNodeInGroup(nodeId));
        // move element inside this 
        oldNodes.forEach(nodeId => this.moveElementNodeFromGroup(nodeId));
    }

    componentWillUnmount() {
        this.gesture.unlistenMouseEvents();
    }

    /**
     * 
     * @param {*} nodeId 
     */
    moveElementNodeInGroup = nodeId => {
        const element = this.props.elements[nodeId];
        this.nodeRectCoodinates[nodeId] = this.selectedRect;
        this.element.current.appendChild(element);
        element.style.left = (this.props.nodes[nodeId].x - this.selectedRect.x) + 'px';
        element.style.top = (this.props.nodes[nodeId].y - this.selectedRect.y) + 'px';
    }

    /**
     * 
     * @param {*} nodeId 
     */
    moveElementNodeFromGroup = nodeId => {
        const element = this.props.elements[nodeId];
        element.style.left = (this.props.nodes[nodeId].x + this.props.delta.x - this.props.container.left) + 'px';
        element.style.top = (this.props.nodes[nodeId].y + this.props.delta.y - this.props.container.top) + 'px';
        this.element.current.parentNode.appendChild(element);
        delete this.nodeRectCoodinates[nodeId];
    }

    /**
     * 
     */
    setElementDragGestureListener() {
        let nodeStartPositions = {};
        this.gesture
            .when(({ event }) => event.which == 1)
            .onStart(() => {
                nodeStartPositions = Object.fromEntries(this.props.selectedNodes.map(nodeId => [nodeId, {
                    x: this.props.nodes[nodeId].x,
                    y: this.props.nodes[nodeId].y,
                }]));
            })
            .onMove(({ delta }) => {
                this.props.moveNodes(Object.fromEntries(Object.keys(nodeStartPositions).map(nodeId => [nodeId, {
                    x: Math.round((nodeStartPositions[nodeId].x + delta.x) / this.props.grid.x) * this.props.grid.x,
                    y: Math.round((nodeStartPositions[nodeId].y + delta.y) / this.props.grid.y) * this.props.grid.y,
                }])));
            })
            .onStop(({ delta }) => {
                this.props.moveNodes(Object.fromEntries(Object.keys(nodeStartPositions).map(nodeId => [nodeId, {
                    x: Math.round((nodeStartPositions[nodeId].x + delta.x) / this.props.grid.x) * this.props.grid.x,
                    y: Math.round((nodeStartPositions[nodeId].y + delta.y) / this.props.grid.y) * this.props.grid.y,
                }])));
            });
    }

    /**
     * 
     * @returns 
     */
    computeSelectedRect = () => {
        const rects = this.props.selectedNodes.map(nodeId => ({
            x: this.props.nodes[nodeId].x - this.props.sizes[nodeId].width / 2,
            y: this.props.nodes[nodeId].y - this.props.sizes[nodeId].height / 2,
            width: this.props.sizes[nodeId].width,
            height: this.props.sizes[nodeId].height,
        }));
        return getEncapsulatedRect(rects);
    }

    render() {
        // compute the selected rect
        const isEmpty = this.props.selectedNodes.length == 0;
        this.selectedRect = this.computeSelectedRect();
        return <div
            ref={this.element}
            style={{
                display: isEmpty ? 'hidden' : 'block',
                position: 'absolute',
                ...(!isEmpty) ? {
                    left: this.selectedRect.x + this.props.delta.x - this.props.container.left + 'px',
                    top: this.selectedRect.y + this.props.delta.y - this.props.container.top + 'px',
                    width: this.selectedRect.width + 'px',
                    height: this.selectedRect.height + 'px'
                } : {},
            }}>
            <div style={{
                width: '100%',
                height: '100%',
                border: '1px solid #3959ff',
                backgroundColor: '#3959ff12',
                borderRadius: '1px',
            }} />
        </div>
    }

});

export default InfiniteSelected;