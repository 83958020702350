import React from 'react';
import { chain, withContext } from '../utils/WithContext';
import InfiniteContext from './InfiniteContext';

/**
 * 
 * @param {*} param0 
 * @param {*} param1 
 * @returns 
 */
const getSquareSegments = ({ x, y }, { width, height }, padding = 0) => {
    return [
        [[-1, -1], [-1, 1]],
        [[-1, -1], [1, -1]],
        [[1, 1], [-1, 1]],
        [[1, 1], [1, -1]],
    ].map(([[sx1, sy1], [sx2, sy2]]) => ([
        x + sx1 * (width / 2 + padding), //x1
        y + sy1 * (height / 2 + padding), //y1
        x + sx2 * (width / 2 + padding), //x2
        y + sy2 * (height / 2 + padding), //y2
    ]));
}


// line intercept math by Paul Bourke http://paulbourke.net/geometry/pointlineplane/
// Determine the intersection point of two line segments
// Return FALSE if the lines don't intersect
const getIntersection = ([x1, y1, x2, y2], [x3, y3, x4, y4]) => {
    // Check if none of the lines are of length 0
    if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
        return false;
    }

    var denominator = ((y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1))

    // Lines are parallel
    if (denominator === 0) {
        return false;
    }

    let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
    let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;

    // is the intersection along the segments
    if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
        return false;
    }

    // Return a object with the x and y coordinates of the intersection
    let x = x1 + ua * (x2 - x1);
    let y = y1 + ua * (y2 - y1);

    return [x, y];
}

const InfiniteEdge = chain(
    withContext(InfiniteContext)(({
        delta,
        container,
        nodes,
        sizes,
    }, props) =>
    ({
        delta,
        container,
        startNode: nodes[props.start],
        endNode: nodes[props.end],
        startSize: sizes[props.start],
        endSize: sizes[props.end],
    })),
)(class extends React.Component {

    constructor(props) {
        super(props);
        this.element = React.createRef();
    }

    componentDidMount() {
        this.componentDidMountOrUpdate();
    }

    componentDidUpdate() {
        this.componentDidMountOrUpdate();
    }

    componentDidMountOrUpdate() {
        if (!this.props.startNode || !this.props.endNode) {
            console.warn('Unable to draw a line');
            return;
        }
        const [start, end] = this.getLine();
        const style = this.getStyleToConnect(start, end, 2);
        // apply style WITHOUT triggering render.
        Object.keys(style).forEach((key) => {
            this.element.current.style[key] = style[key];
        });
    }


    /**
     * 
     * @returns 
     */
     getLine = () => {
        const startSideSegments = getSquareSegments(this.props.startNode, this.props.startSize || [0,0], 8);
        const endSideSegments = getSquareSegments(this.props.endNode, this.props.endSize || [0,0], 8 + 3);

        var line = [
            this.props.startNode.x,
            this.props.startNode.y,
            this.props.endNode.x,
            this.props.endNode.y,
        ];

        var startPosition = [
            this.props.startNode.x,
             this.props.startNode.y,
        ];
             startSideSegments.forEach(segment => {
            var intersection = getIntersection(line, segment);
            if (intersection) startPosition = intersection;
        });

        var endPosition = [ 
            this.props.endNode.x,
             this.props.endNode.y,
        ];
             endSideSegments.forEach(segment => {
            var intersection = getIntersection(line, segment);
            if (intersection) endPosition = intersection;
        });

        return [startPosition, endPosition];
    }


    /**
     * 
     * @param {*} x1 
     * @param {*} y1 
     * @param {*} x2 
     * @param {*} y2 
     * @param {*} height 
     * @returns 
     */
    getStyleToConnect([x1, y1], [x2, y2], height) {
        // distance
        let length = Math.sqrt(((x1 - x2) * (x1 - x2)) + ((y1 - y2) * (y1 - y2)))
        // center
        let cx = ((x2 + x1) / 2) - (length / 2),
            cy = ((y2 + y1) / 2) - (height / 2);
        // angle
        let angle = Math.atan2((y2 - y1),
            (x2 - x1)) * (180 / Math.PI);
        return {
            //height: `${height}px`,
            left: `${cx + this.props.delta.x - this.props.container.left}px`,
            top: `${cy + this.props.delta.y - this.props.container.top}px`,
            width: `${length}px`,
            transform: `rotate(${angle}deg)`,
        }
    }



    /**
     * 
     * @returns 
     */
    render() {
        return <div
            ref={this.element}
            style={{
                position: 'absolute',
                height: 2,
                backgroundColor: '#246',
            }}>
            <div style={{
                position: 'absolute',
                right: -3,
                top: -2,
                borderBottom: '3px solid transparent',
                borderLeft: '9px solid #246',
                //borderLeftColor: '#000',
                borderTop: '3px solid transparent',
            }} />
            
{/* Text */}
{false && <div className={'text'}
                style={{
                    position: 'absolute',
                    left: 0,
                    top: -16,
                    lineHeight: '12px',
                    //backgroundColor: '#246',
                    //transform: 'translateX(-50%)',
                    //borderRadius: '9px',
                    color: '#246',
                    borderRadius: '10px',
                    //border: '1px solid ' + this.props.color,
                    padding: '0px 6px',
                    //boxShadow: '0 0 4px rgba(0,0,0,0.3)',
                }}>
                <span style={{
                    fontSize: 11,
                    fontWeight: 600,
                    letterSpacing: '-.2px',
                    verticalAlign: 'middle',
                }}>{"users_collection"}</span>
            </div>}

        </div>
    }

});

export default InfiniteEdge;