

export default ({ name, mandatory, description, children, helper }) => (
    <div>
        {name && helper && <div style={{ mb: '8px', display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
            <span style={{ fontWeight: 500 }}
                variant="body2">
                {name} {mandatory && <span style={{ color: '#c32424' }}>*</span>}
            </span>
            <div style={{ flexGrow: 1 }}></div>
            {helper}
        </div>}

        {name && !helper && <span component='div' style={{ mb: '8px', fontWeight: 400, color: 'rgba(0,0,0,.5)' }}
            variant="body2">
            {name} {mandatory && <span style={{ color: '#c32424' }}>*</span>}
        </span>}

        {description && <span component='div' style={{ mb: '8px', flexGrow: 1, fontWeight: 400 }}
            variant="body2">
            {description}
        </span>}

        {children}

    </div>
)

