import { mdiChevronDown, mdiChevronRight } from '@mdi/js';
import Icon from '@mdi/react';
import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const Header = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    user-select: none;
    cursor: default;
    //border-radius: 4px;
    //margin-left: -14px;
`;

const TitleContainer = styled.div`
    flex-grow: 1;
    height: 26px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    gap: 2px;
    align-items: center;
    &:hover > *:first-child {
        opacity: 1
    }
    & > *:first-child {
        opacity: 0;
        transition: opacity .2s;
    }
`;

const Title = styled.span`
    font-weight: 500;
`;

const ButtonContainer = styled.div`
    flex-shrink: 0;
    display: flex;
    gap: 2px;
`;

const Content = styled.div`
    display: ${({ open }) => (open ? 'flex' : 'none')};
    flex-direction: column;
    min-height: 24px;
`;

export default class CollapsingBox extends React.Component {

    render() {
        return (
            <Container>
                <Header>
                    <TitleContainer onClick={this.props.onClick}>
                        <Icon
                            style={{ flexShrink: 0 }}
                            path={this.props.open ? mdiChevronDown : mdiChevronRight}
                            size={0.6}
                        />
                        <Title>{this.props.title}</Title>
                    </TitleContainer>
                    <ButtonContainer>{this.props.buttons}</ButtonContainer>
                </Header>
                <Content open={this.props.open || true}>{this.props.children}</Content>
            </Container>
        );
    }
}