import React from 'react';
import AuthenticationContext from '../../authentication/AuthenticationContext.js';
import ArtifactBrowserContext from '../../browser/ArtifactBrowserContext.js';
import ArtifactBrowserProvider from '../../browser/ArtifactBrowserProvider.js';
import ArtifactEditor from '../../editor/ArtifactEditor.js';
import ArtifactEditorContext from '../../editor/ArtifactEditorContext.js';
import ArtifactEditorProvider from '../../editor/ArtifactEditorProvider.js';
import ResourceContext from '../../stack/ResourceContext.js';
import ResourceProvider from '../../stack/ResourceProvider.js';
import StackContext from '../../stack/StackContext.js';
import { chain, withContext } from '../../utils/WithContext.js';
import ComponentPanel from './ComponentPanel.js';
import StackAppBar from './StackAppBar.js';
import StackPageContext from './StackPageContext.js';
import PopupProvider from '../../popup/PopupProvider.js';


const StackPage = chain(
    withContext(StackContext)(({ stackId, stack, artifacts, build }) => ({ stackId, stack, artifacts, build })),
    withContext(StackPageContext)(({ openPageResource, openedPageResourceIds, }) => ({ openPageResource, openedPageResourceIds })),
)(class extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.openPageResource(this.props.stackId);
    }

    componentDidUpdate() {
        console.log('UPDATE', this.props.openedResources)
    }


    render() {
        const resourceId = this.props.openedPageResourceIds[0];
        const artifact = this.props.artifacts[resourceId];

        return (
            <>
                {artifact ? <ResourceProvider resourceId={resourceId} artifact={artifact}>
                    <ArtifactBrowserProvider key={resourceId} artifact={artifact}>
                        <ArtifactEditorProvider key={resourceId} artifact={artifact}>
                            <ModuleComponent resourceId={resourceId} artifact={artifact} />
                        </ArtifactEditorProvider>
                    </ArtifactBrowserProvider>
                </ResourceProvider> : <>Loading!! {resourceId}</>}
            </>
        );
    }
});




const ModuleComponent = chain(
    withContext(AuthenticationContext)(({ user }) => ({ user })),
    withContext(ArtifactEditorContext)(({ filepath, artifact, openFile, closeFile }) => ({ filepath, artifact, openFile, closeFile })),
    withContext(ArtifactBrowserContext)(({ selectedPath, addFile, addFolder }) => ({ selectedPath, addFile, addFolder })),
    withContext(StackContext)(({ stack, artifacts }) => ({ stack, artifacts })),
    withContext(StackPageContext)(({ openPageResource, openedPageResourceIds }) => ({ openPageResource, openedPageResourceIds })),
    withContext(ResourceContext)(({ component, service }) => ({ component, service })),
)(class extends React.Component {

    componentDidMount() {
        this.props.artifact.getFileAsJson('/resource.json').then(resource => {
            if (resource && resource.service) {
                this.props.openFile('/resource.json');
            } else {
                this.props.openFile('/module.json');
            }
        })
    }

    componentDidUpdate(oldProps) {
        if (this.props.selectedPath !== oldProps.selectedPath) {
            this.props.openFile(this.props.selectedPath);
        }
    }

    render() {
        if (!this.props.component) return <></>

        return (
            <PopupProvider>
            <div style={{
                width: '100%',
                height: '100%',
                position: 'relative',
                //background: '#f2f2f6',
                //backgroundColor: '#EEEEFF',
                //background: 'linear-gradient(140deg, rgb(230 234 239) 0%, rgb(239, 254, 239) 100%)',
                backgroundImage: 'linear-gradient(to bottom, #dfe9f3 0%, white 100%)',
                //background: '#eff3f6',//'#FCFCFC',


            }}>

                <StackAppBar />

                <div style={{
                    height: '100%',
                    width: '100%',
                    padding: '70px 70px 0px 70px',
                }}>

                    <div style={{
                        height: '100%',
                        width: '100%',
                        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                        display: 'flex',
                        borderRadius: '2px',
                        borderTopLeftRadius: '16px',
                        borderTopRightRadius: '16px',
                        backgroundColor: 'rgba(255,255,255,.6)'
                    }}>
                        <div style={{ width: '320px', flexShrink: 0, padding: '16px 24px', borderRight: '1px solid rgba(0,0,0,.02)' }}>
                            <ComponentPanel />
                        </div>
                        <div style={{ flexGrow: 1 }}>
                            <ArtifactEditor getEditorFile={filepath => (filepath === '/module.json') ? 'module' : 'code'} />
                        </div>
                    </div>
                </div>
            </div>
            </PopupProvider>
        );
    }
});



export default StackPage;

