import { mdiArrowLeft, mdiArrowRight, mdiChevronDown, mdiChevronUp, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import styled, { css } from 'styled-components';
import IconButton from '../../design/IconButton';
import ResourceProvider from '../../stack/ResourceProvider';
import StackContext from '../../stack/StackContext';
import { chain, withContext } from '../../utils/WithContext';
import { ComponentName } from './Component';
import StackPageContext from './StackPageContext';
import { mdilUnfoldMoreHorizontal } from '@mdi/light-js';
import { iconChevronDown, iconDelete } from '../../icons/LightIcons';

const DropDownButtonContainer = styled.div`
    position: relative;
    width: 100%;
    min-width: 600px;
    display: flex;
    align-items: center;
    gap: 2px;
    //min-width: 180px;
    //max-width: 260px;
    height: 32px;
    //font-size: 12px;
`;

const DropDownButton = styled.div`
    position: relative;
    flex-grow: 1;
    z-index: 2;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    gap: 8px;

    padding: 4px 8px;
    background-color: rgba(97, 142, 170, .1);
    //background-color: rgba(0,0,0,.06);//#f4f5f7; //#eef0f3;
    border-radius: 6px;
    border-bottom: 1px solid transparent;

    cursor: default;

    //box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 0.5px 0px, rgba(0, 0, 0, 0.15) 0px 1px 3px 0px;

    transition: background-color .1s;

    &:hover {
        background-color: #e0e6ea;
        border-bottom: 1px solid rgba(97, 142, 170, .14);
    }

    ${(props) =>
         props.isPopupOpen &&
        css`
        border-bottom: 1px solid rgba(0,0,0,.05);
        box-shadow: none;
        //border-bottom: none;
        background-color: #f5f5f5;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    `}
`;

const Popup = styled.div`
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #e0e6ea;
    //border: 1px solid #eff0f2;
    border-radius: 6px;
    padding: 40px 0px 8px;
    box-shadow: rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;
    //box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0.5px 0px, rgba(0, 0, 0, 0.18) 0px 10px 24px 0px, rgba(0, 0, 0, 0.15) 0px 2px 5px 0px
    `;

const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const ListItem = styled.div`
    height: 28px;
    padding: 0px 3px 0px 8px;
    display: flex;
    gap: 8px;
    align-items: center;
    cursor: default;
    transition: background-color .15s;
    background-color: ${(props) => props.isOpen ? '#e5f4ff' : 'transparent'};
    &:hover {
        background-color: ${(props) => props.isOpen ? '#e5f4ff' : '#e5f4ff'};
    }
`;

const ActionContainer = styled.div`
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`
const EditedTag = styled.div`
    height: 8px;
    width: 8px;
    border-radius: 4px;
    background-color: #F80;
`;

const Spacer = styled.div`
    flex-grow: 1;
`;

const ComponentsDropDown = chain(
    withContext(StackContext)(({ stack, artifacts, artifactVersions, openedResourceIds }) => ({ stack, artifacts, artifactVersions, openedResourceIds })),
    withContext(StackPageContext)(({ openPageResource, closePageResource, openedPageResourceIds }) => ({ openPageResource, closePageResource, openedPageResourceIds })),
)(
    class extends React.Component {

        constructor() {
            super();
            this.state = {
                isPopupOpen: false
            }
            this.buttonRef = React.createRef();
            this.popupRef = React.createRef();
        }

        componentDidMount() {
            document.body.addEventListener('mousedown', this.handleClickOutside);
        }

        componentWillUnmount() {
            document.body.removeEventListener('mousedown', this.handleClickOutside);
        }

        handleClick = () => {
            this.setState({ isPopupOpen: !this.state.isPopupOpen });
        };

        handleClickOutside = event => {
            if (this.popupRef.current && !this.popupRef.current.contains(event.target) && !this.buttonRef.current.contains(event.target)) {
                this.setState({ isPopupOpen: false });
            }
        };

        render() {
            if (!this.props.openedPageResourceIds[0]) return <></>;


            return (
                <DropDownButtonContainer>

                    <DropDownButton
                        ref={this.buttonRef}
                        onClick={this.handleClick}
                        isPopupOpen={this.state.isPopupOpen}>
                        <div style={{ flexGrow: 1 }}>
                            <ResourceProvider
                                resourceId={this.props.openedPageResourceIds[0]}
                                artifact={this.props.artifacts[this.props.openedPageResourceIds[0]]}>
                                <ComponentName />
                            </ResourceProvider>

                        </div>
                        <Icon path={iconChevronDown} size={1} />
                    </DropDownButton>

                    {this.state.isPopupOpen && <Popup
                        ref={this.popupRef}
                        tabIndex={-1}
                    >
                        <ListContainer>
                            {this.props.openedResourceIds.filter(resourceId => {
                                const artifact = this.props.artifacts[resourceId];
                                return artifact && (this.props.artifactVersions[artifact.id] > 0 || this.props.openedPageResourceIds.includes(resourceId));
                            }).map((resourceId, index) => (
                                <ListItem
                                    key={index}
                                    onClick={() => this.props.openPageResource(resourceId)}
                                    isOpen={this.props.openedPageResourceIds[0] === resourceId}>
                                    <ResourceProvider resourceId={resourceId} artifact={this.props.artifacts[resourceId]}>
                                        <ComponentName />
                                    </ResourceProvider>
                                    <Spacer />
                                    <ActionContainer>
                                        {this.props.artifactVersions[this.props.artifacts[resourceId].id] > 0
                                            ?
                                            <EditedTag />
                                            :
                                            <IconButton onClick={event => {
                                                event.stopPropagation();
                                                this.props.closePageResource(resourceId)
                                            }} path={iconDelete} />}
                                    </ActionContainer>
                                </ListItem>
                            ))}
                        </ListContainer>
                    </Popup>}
                </DropDownButtonContainer>
            )
        }
    });


export default ComponentsDropDown;
