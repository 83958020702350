import React from "react";


class SystemButton extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { onClick, children } = this.props;
        return <button
            onClick={onClick}
            variant='contained'
            style={{
                '&:hover': {
                    backgroundColor: '#000',
                },
                '&:active': {
                    backgroundColor: '#000',
                },
                color: 'white',
                fontSize: 12,
                fontWeight: 500,
                borderRadius: '3px',
                padding: '8px 12px',
                boxShadow: 'none',
                backgroundColor: '#112',

            }}>{children}</button>
    }

}

export default SystemButton;
