import React from 'react';

class SystemTitle extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { children } = this.props;
        return <span
            variant='h4'
            style={{
                fontWeight: 600,
            }}>{children}</span>
    }

}

export default SystemTitle;