import React from 'react';
import { chain, withContext } from '../utils/WithContext';
import ArtifactEditorContext from './ArtifactEditorContext';
import CodeEditorFile from './CodeEditorFile';
import ModuleEditorFile from './ModuleEditorFile';

/**
 * Add a new editor reference here
 * must accept a filepath as a props.
 */
const EDITORS = {
    code: CodeEditorFile,
    module: ModuleEditorFile,
}

/**
 * 
 */
class EditorFile extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const Editor = EDITORS[this.props.editor];
        return <Editor filepath={this.props.filepath} />
    }
}

/**
 * 
 */
const ArtifactEditor = chain(
    withContext(ArtifactEditorContext)(({ filepath, filepaths, openFile }) => ({ filepath, filepaths, openFile })),
)(class extends React.Component {

    getEditorFile = filepath => {
        if (this.props.getEditorFile) {
            return this.props.getEditorFile(filepath);
        }
        return 'code';
    }

    render() {
        console.log(this.props.filepaths)
        return <div style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        }}>
            <div style={{ height: '36px', display: 'flex'}}>
            {this.props.filepaths.map(filepath => (

                <div style={{ 
                    display: 'flex', 
                    alignItems: 'center',
                     padding: '0 24px', 
                     backgroundColor: (filepath === this.props.filepath) ? '#fff' : 'rgba(0,0,0,.02)',
                     color: (filepath === this.props.filepath) ? '#000' : 'rgba(0,0,0,.6)',
                      borderRight: '1px solid #EEE',
                       borderBottom: (filepath === this.props.filepath) ? '1px solid transparent' : '1px solid #EEE'
                    
                    }}
                    onClick={() => this.props.openFile(filepath)}
                    >{filepath}</div>

            ))}

            </div>
            <div style={{
                flexGrow: 1,
            }}>
            {this.props.filepaths.map(filepath => (
                <div
                    key={filepath}
                    style={{
                        width: '100%',
                        height: '100%',
                        display: (filepath === this.props.filepath) ? 'block' : 'none',
                    }}>
                    <EditorFile filepath={filepath} editor={this.getEditorFile(filepath)} />
                </div>
            ))}
            </div>
        </div>
    }
})

export default ArtifactEditor;

