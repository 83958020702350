import { getIdToken } from '../Firebase.js';

export const createStack = ({ organization, stackName }) => {
    return getIdToken().then(idToken => {
        return fetch('https://api.blueforge.cloud/v1alpha/stacks', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ organization, stackName })
        }).then(response => response.json())
    });
}

export const getStack = stackId => {
    return getIdToken().then(idToken => {
        return fetch(`https://api.blueforge.cloud/v1alpha/stacks/${stackId}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    });
}

export const getStackPublication = (stackId, publicationId) => {
    return getIdToken().then(idToken => {
        return fetch(`https://api.blueforge.cloud/v1alpha/stacks/${stackId}/publications/${publicationId}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    });
}

export const getStackVersions = stackId => {
    return getIdToken().then(idToken => {
        return fetch(`https://api.blueforge.cloud/v1alpha/stacks/${stackId}/versions`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    });
}

export const publishStackBuild = (stackId, buildId) => {
    return getIdToken().then(idToken => {
        return fetch(`https://api.blueforge.cloud/v1alpha/stacks/${stackId}/builds/${buildId}:publish`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            },
        }).then(response => response.json())
    });
}

export const createHost = ({ organization, hostName, backendPairingUrl }) => {
    return getIdToken().then(idToken => {
        return fetch('https://api.blueforge.cloud/v1alpha/hosts', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ organization, hostName, backendPairingUrl })
        }).then(response => response.json())
    });
}

export const getHostState = hostId => {
    return getIdToken().then(idToken => {
        return fetch(`https://api.blueforge.cloud/v1alpha/hosts/${hostId}/state`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    });
}

export const createHostDeployment = (hostId, appId, versionId) => {
    return getIdToken().then(idToken => {
        return fetch(`https://api.blueforge.cloud/v1alpha/hosts/${hostId}/deployments`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ appId, versionId })
        }).then(response => response.json())
    });
}

export const getHostDeployment = (hostId, deploymentId) => {
    return getIdToken().then(idToken => {
        return fetch(`https://api.blueforge.cloud/v1alpha/hosts/${hostId}/deployments/${deploymentId}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    });
}

export const getHostDeploymentOperation = (hostId, operationId) => {
    return getIdToken().then(idToken => {
        var request = `https://api.blueforge.cloud/v1alpha/hosts/${hostId}/operations/${operationId}`;
        return fetch(request, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if ([200, 201].includes(response.status)) {
                return response.json()
            } else {
                return response.json().then(err => {
                    throw new Error(err.message || 'An unexpected error occurs.');
                });
            }
        })
    });
}

export const createApp = ({ organization, appName, hostId, stackId }) => {
    return getIdToken().then(idToken => {
        return fetch('https://api.blueforge.cloud/v1alpha/apps', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ organization, appName, hostId, stackId })
        }).then(response => response.json())
    });
}

export const getApp = appId => {
    return getIdToken().then(idToken => {
        return fetch(`https://api.blueforge.cloud/v1alpha/apps/${appId}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    });
}

export const applyAppPlan = (appId, planId) => {
    return getIdToken().then(idToken => {
        return fetch(`https://api.blueforge.cloud/v1alpha/apps/${appId}/plans/${planId}:apply`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    });
}

export const createAppPlan = (appId, { buildId }) => {
    return getIdToken().then(idToken => {
        return fetch(`https://api.blueforge.cloud/v1alpha/apps/${appId}/plans`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ buildId })
        }).then(response => response.json())
    });
}

export const getAppStatus = appId => {
    return getIdToken().then(idToken => {
        return fetch(`https://api.blueforge.cloud/v1alpha/apps/${appId}/status`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    });
}

export const getAppState = appId => {
    return getIdToken().then(idToken => {
        return fetch(`https://api.blueforge.cloud/v1alpha/apps/${appId}/state`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    });
}

export const createService = ({ id, roles }) => {
    return getIdToken().then(idToken => {
        return fetch('https://api.blueforge.cloud/v1alpha/services', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id, roles })
        }).then(response => response.json())
    });
}


export const getEnvInfo = envId => {
    return getIdToken().then(idToken => {
        return fetch(`https://api.blueforge.cloud/v1alpha/envs/${envId}/info`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    });
}

export const putEnvApp = (envId, appId, { versionId, parameters }) => {
    return getIdToken().then(idToken => {
        return fetch(`https://api.blueforge.cloud/v1alpha/envs/${envId}/apps/${appId}`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ versionId, parameters })
        }).then(response => response.json())
    });
}

export const getEnvAppMask = (envId, appId, { versionId, parameters }) => {
    return getIdToken().then(idToken => {
        return fetch(`https://api.blueforge.cloud/v1alpha/envs/${envId}/apps/${appId}/mask`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ versionId, parameters })
        }).then(response => response.json())
    });
}

export const getEnvServices = (envId) => {
    return getIdToken().then(idToken => {
        return fetch(`https://api.blueforge.cloud/v1alpha/envs/${envId}/services`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    });
}

export const getEnvApps = (envId) => {
    return getIdToken().then(idToken => {
        return fetch(`https://api.blueforge.cloud/v1alpha/envs/${envId}/apps`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    });
}

export const createStackBuild = (stackId, patch) => {
    return getIdToken().then(idToken => {
        return fetch(`https://api.blueforge.cloud/v1alpha/stacks/${stackId}/builds`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(patch)
        }).then(response => response.json())
    });
}

export const createServiceBuild = (serviceId, patch) => {
    return getIdToken().then(idToken => {
        return fetch(`https://api.blueforge.cloud/v1alpha/services/${serviceId}/builds`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(patch)
        }).then(response => response.json())
    });
}

export const publishServiceBuild = (serviceId, buildId) => {
    return getIdToken().then(idToken => {
        return fetch(`https://api.blueforge.cloud/v1alpha/services/${serviceId}/builds/${buildId}:publish`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            },
        }).then(response => response.json())
    });
}

// /projects/:projectId/stacks/:resourceName/versions/:versionId/:tree
// apps/okapp-953067/main/c08ec3b4e0ce6edb1177dc6e34c70c43c9ae3f02.zip
/*export const getArtifactFiles = (artifact, folder) => {
    let request = `https://api.blueforge.cloud/v1alpha/artifacts/files`;
    if (folder) request += `?artifact=${artifact}&folder=${folder}`;
    return getIdToken().then(idToken => {
        return fetch(request, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    });
}*/

/**
 * 
 * @param {*} remoteUri registry/@default/sdfds/
 * @param {*} folder 
 * @returns 
 */
export const getArtifactFiles = (remoteUri, folder) => {
    const index = remoteUri.indexOf('/');
    const type = remoteUri.substring(0, index);
    const artifact = remoteUri.substring(index + 1).replace(':', '/');
    return getIdToken().then(idToken => {
        const request = `https://${type}.blueforge.cloud/files/${artifact}.zip${folder}`;
        return fetch(request, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + idToken
            }
        }).then(response => response.json());
    })
}

/**
 * 
 * @param {*} type registry or services
 * @param {*} artifact @default/stackName/resourceIdA/resourceIdB:version
 * @param {*} filepath /folder/test.js
 * @returns 
 */
export const downloadArtifactFile = (remoteUri, filepath) => {
    const index = remoteUri.indexOf('/');
    const type = remoteUri.substring(0, index);
    const artifact = remoteUri.substring(index + 1).replace(':', '/');
    return getIdToken().then(idToken => {
        const request = `https://${type}.blueforge.cloud/blob/${artifact}.zip${filepath}`;
        return fetch(request, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + idToken
            }
        }).then(response => response.text());
    })
}

export const downloadStackFile = (resourceId, buildId, filepath) => {
    return getIdToken().then(idToken => {
        const request = `https://registry.blueforge.cloud/blob/${resourceId}/${buildId}.zip${filepath}`;
        return fetch(request, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + idToken
            }
        }).then(response => response.text());
    })
}

export const getStackFiles = (resourceId, buildId, folder) => {
    return getIdToken().then(idToken => {
        const request = `https://registry.blueforge.cloud/files/${resourceId}/${buildId}.zip${folder}`;
        return fetch(request, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + idToken
            }
        }).then(response => response.json());
    })
}

export const downloadServiceFile = (serviceId, buildId, filepath) => {
    return getIdToken().then(idToken => {
        const request = `https://services.blueforge.cloud/blob/${serviceId}/${buildId}.zip${filepath}`;
        return fetch(request, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + idToken
            }
        }).then(response => response.text());
    })
}

export const getServiceFiles = (serviceId, buildId, folder) => {
    return getIdToken().then(idToken => {
        const request = `https://services.blueforge.cloud/files/${serviceId}/${buildId}.zip${folder}`;
        return fetch(request, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + idToken
            }
        }).then(response => response.json());
    })
}

// /projects/:projectId/stacks/:resourceName/versions/:versionId/:tree
// apps/okapp-953067/stacks/main.module/versions/c08ec3b4e0ce6edb1177dc6e34c70c43c9ae3f02.zip
export const getArchitecture = (appId, opt_versionId) => {
    let request;
    if (opt_versionId) {
        request = `https://api.blueforge.cloud/v1alpha/apps/${appId}/versions/${opt_versionId}/architecture`;
    } else {
        request = `https://api.blueforge.cloud/v1alpha/apps/${appId}/architecture`;
    }
    return getIdToken().then(idToken => {
        return fetch(request, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    });
}


export const getEnv = appId => {
    return getIdToken().then(idToken => {
        return fetch(`https://api.blueforge.cloud/v1alpha/apps/${appId}/env`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    });
}

export const getEnvDeployments = (envId, opt_states) => {
    return getIdToken().then(idToken => {
        var request = `https://api.blueforge.cloud/v1alpha/envs/${envId}/deployments`;
        if (opt_states) request += `?states=${opt_states.join(',')}`
        return fetch(request, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if ([200, 201].includes(response.status)) {
                return response.json()
            } else {
                return response.json().then(err => {
                    throw new Error(err.message || 'An unexpected error occurs.');
                });
            }
        })
    });
}

export const getEnvDeployment = (envId, deploymentId) => {
    return getIdToken().then(idToken => {
        var request = `https://api.blueforge.cloud/v1alpha/envs/${envId}/deployments/${deploymentId}`;
        return fetch(request, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if ([200, 201].includes(response.status)) {
                return response.json()
            } else {
                return response.json().then(err => {
                    throw new Error(err.message || 'An unexpected error occurs.');
                });
            }
        })
    });
}

export const initializeProject = (projectId, data) => {
    return getIdToken().then(idToken => {
        return fetch(`https://api.blueforge.cloud/alpha/projects/${projectId}/:initialize`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ projectId: data.projectId })
        }).then(response => response.json())
    });
}


/*export const createVersion = (projectId, json) => {
    return getIdToken().then(idToken => {
        return fetch(`https://api.blueforge.cloud/alpha/projects/${projectId}/versions`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(json)
        }).then(response => response.json())
    });
}*/

/*export const createInstance = (projectId, json) => {
    return getIdToken().then(idToken => {
        return fetch(`https://api.blueforge.cloud/alpha/projects/${projectId}/instance`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(json)
        }).then(response => response.json())
    });
}*/

export const getProject = (projectId) => {
    return getIdToken().then(idToken => {
        return fetch(`https://api.blueforge.cloud/alpha/projects/${projectId}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    });
}

export const getOperation = (projectId, operationId) => {
    return getIdToken().then(idToken => {
        return fetch(`https://api.blueforge.cloud/alpha/projects/${projectId}/operations/${operationId}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    });
}


export const getServices = services => {
    var request = "https://api.blueforge.cloud/v1alpha/services";
    if (services) request += `?services=` + services.join(',');
    return getIdToken().then(idToken => {
        return fetch(request, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    });
}

export const getService = (serviceId, versionId) => {
    var request = `https://api.blueforge.cloud/v1alpha/services/${serviceId}`;
    if (versionId) request += `/versions/${versionId}`;
    return getIdToken().then(idToken => {
        return fetch(request, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    });
}

export const getServicePublication = (serviceId, publicationId) => {
    return getIdToken().then(idToken => {
        return fetch(`https://api.blueforge.cloud/v1alpha/services/${serviceId}/publications/${publicationId}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    });
}

export const enableService = (projectId, serviceId, version) => {
    return getIdToken().then(idToken => {
        return fetch(`https://api.blueforge.cloud/alpha/projects/${projectId}/services/${serviceId}/${version}/:enable`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    });
}


export const getProjectTree = (projectId, folder) => {
    return getIdToken().then(idToken => {
        return fetch(`https://api.blueforge.cloud/alpha/projects/${projectId}/tree?folder=${folder}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    });
}



export const deployVersion = (projectId, versionId) => {
    return getIdToken().then(idToken => {
        return fetch(`https://api.blueforge.cloud/alpha/projects/${projectId}/versions/${versionId}/:deploy`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    });
}



/*class Backend {

    constructor(user) {
        super();
        this.user = user;
    }


    uploadFile(projectId, filename, jsonString, progressCallback) {
        return new Promise((resolve, reject) => {
            let path = this.user.uid + '/' + projectId + '/' + filename;
            var blob = new Blob([jsonString], { type: "application/json" })
            firebase.app().storage("gs://blueforge-user-projects-1").ref().child(path).put(blob)
                .on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                    (snapshot) => {
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        progressCallback && progressCallback(progress, filename);
                        switch (snapshot.state) {
                            case firebase.storage.TaskState.PAUSED: // or 'paused'
                                console.log('Upload is paused');
                                break;
                            case firebase.storage.TaskState.RUNNING: // or 'running'
                                console.log('Upload is running');
                                break;
                        }
                    },
                    (error) => {
                        // A full list of error codes is available at
                        // https://firebase.google.com/docs/storage/web/handle-errors
                        switch (error.code) {
                            case 'storage/unauthorized':
                                // User doesn't have permission to access the object
                                break;
                            case 'storage/canceled':
                                // User canceled the upload
                                break;

                            // ...

                            case 'storage/unknown':
                                // Unknown error occurred, inspect error.serverResponse
                                break;
                        }
                        reject(error);
                    },
                    () => {
                        resolve(filename);
                    }
                );
        });
    }

    downloadFile(projectId, filename) {
        return new Promise((resolve, reject) => {
            firebase.app().storage('gs://blueforge-user-projects-1').ref()
                .child(this.user.uid + '/' + projectId + filename)
                .getDownloadURL()
                .then((url) => {
                    fetch(url)
                        .then(res => res.json())
                        .then(out => resolve(out))
                        .catch(err => { reject(err) });
                })
                .catch((err) => {
                    reject(err)
                });
        });
    }

    listFiles(projectId, folder) {
        firebase.app().storage('gs://blueforge-user-projects-1').ref().child(this.user.uid + '/' + projectId + folder)
            .listAll()
            .then((res) => {
                res.prefixes.forEach((folderRef) => {
                    // All the prefixes under listRef.
                    // You may call listAll() recursively on them.
                });
                res.items.forEach((itemRef) => {
                    // All the items under listRef.
                });
            }).catch((error) => {
                // Uh-oh, an error occurred!
            });
    }

    getMetadata(projectId, filename) {
        return firebase.app().storage('gs://blueforge-user-projects-1').ref()
            .child(this.user.uid + '/' + projectId + filename)
            .getMetadata();
    }

    updateMetadata(projectId, filename, metadata) {
        return firebase.app().storage('gs://blueforge-user-projects-1').ref()
            .child(this.user.uid + '/' + projectId + filename)
            .updateMetadata(metadata);
    }

}*/