
import * as React from 'react';
import { signInWithEmailAndPassword } from '../Firebase.js';
import Button from '../system/Button.js';
import FormElement from '../system/FormElement.js';
import Input from '../system/Input.js';
import Title from '../system/Title.js';

class Signin extends React.Component {

    constructor(props) {
        super(props);
        this.values = {};
    }

    onChange = name => event => this.values[name] = event.target.value;

    doSignin = () => {
        signInWithEmailAndPassword(this.values.email, this.values.password)
            .then((userCredential) => {
                var user = userCredential.user;
                console.log(`Logged as ${user.email}`);
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log(errorCode, errorMessage)
            });
    }

    render() {
        return (
            <div sx={{
                display: 'flex',
                margin: 'auto',
                gap: '20px',
                width: 400,
            }}>
                <Title>Log in</Title>
                <FormElement name="Email">
                    <Input onChange={this.onChange("email")} sx={{ width: '100%' }} />
                </FormElement>
                <FormElement name="Password">
                    <Input type={"password"} onChange={this.onChange("password")} sx={{ width: '100%' }} />
                </FormElement>
                <Button onClick={this.doSignin}>Sign in</Button>
            </div>
        );
    }
}

export default Signin;