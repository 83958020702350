import React from 'react';
import { chain, withContext } from '../utils/WithContext';
import InfiniteContext from './InfiniteContext';

const InfiniteSelecting = chain(
    withContext(InfiniteContext)(({ selectingRect, delta, container }) => ({ selectingRect, delta, container })),
)(class extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <div style={{
            display: this.props.selectingRect ? 'block' : 'hidden',
            position: 'absolute',
            outline: '1px solid #3959ff',
            backgroundColor: '#3959ff12',
            ...(this.props.selectingRect) ? {
                left: this.props.selectingRect.x + this.props.delta.x - this.props.container.left + 'px',
                top: this.props.selectingRect.y + this.props.delta.y - this.props.container.top + 'px',
                width: this.props.selectingRect.width + 'px',
                height: this.props.selectingRect.height + 'px',
            } : {},
        }} />
    }

});

export default InfiniteSelecting;