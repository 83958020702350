import React from 'react';


class AutoFocusInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: null
        }
        this.element = React.createRef();
    }

    componentDidMount() {
        this.element.current.addEventListener('keyup', event => {
            if (event.key === 'Enter' || event.keyCode === 13) {
                this.handleSubmit();
            }
        });
    }

    handleChange = event => {
        this.setState({
            value: event.target.value
        });
    }

    handleSubmit = () => {
        if (this.props.onChange) this.props.onChange(this.state.value)
    }

    // todo backdrop not working => should we migrate to new mui ?
    render() {
        var { sx, onBlur } = this.props;
        return <input
            ref={this.element}
            autoFocus
            onChange={this.handleChange}
            onBlur={onBlur}
            style={{
                ...sx,
                padding: '4px 8px',
                background: 'rgba(255,255,255, .15)',
                border: '1px solid rgba(0,0,0,.06)',
                color: '#000',
                fontSize: 15,
                '&.Mui-focused': {
                    outline: '1px solid blue'
                }
            }} />
    }

}

export default AutoFocusInput;
