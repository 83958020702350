import DiffMatchPatch from 'diff-match-patch';
import { produce } from 'immer';
import * as React from 'react';
import uuid from 'react-uuid';
import { ResourceArtifact } from '../artifact/Artifact.js';
import { createStackBuild, getStack, getStackArtifactBuildUri } from '../backend/Stacks.js';
import { uploadString } from '../Firebase.js';
import StackContext from './StackContext.js';

const dmp = new DiffMatchPatch();

/**
 * StackProvider manages the state of stack and its artifacts, and provides methods for saving, building, and fetching artifacts.
 */
class StackProvider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            stack: null,
            isSaving: false,
            isBuilding: false,
            // Map resourceId <> artifact.id
            artifacts: {},
            artifactVersions: {},
            // list of lazy opened resources
            openedResourceIds: [],
        };

        // Map artifact.id <> artifact
        this.artifacts = {};
        this.childResourcesUnsubscribes = {};
        this.versionChangesUnsubscribes = {};
        this.artifactsByBuildIds = {}; //cache

        // for the save / build
        this.remoteFileCache = {}; // Cache for user-uploaded files to avoid repeated uploads
        this.lastSavedStackPatch = null; // Last saved patch of the stack
    }

    /**
     * Fetches the stack from the backend and updates the state with stack and its last build URI.
     * @returns {Promise<void>}
     */
    componentDidMount() {
        return getStack(this.props.stackId)
            .then(stack => {
                const artifactUri = stack.lastBuild?.buildId ? `${stack.stackId}:${stack.lastBuild.buildId}` : "@default/module:v1";
                // load the first artifact
                return getStackArtifactBuildUri(artifactUri).then(artifactUri => {
                    const artifact = new ResourceArtifact(artifactUri);
                    this.artifacts[artifact.id] = artifact;
                    this.setState({
                        stack,
                        artifacts: {
                            [this.props.stackId]: artifact.id,
                        }
                    });
                });
            });
    }

    /**
     * 
     * @param {*} resourceId 
     * @returns 
     */
    openResource = resourceId => {
        console.log('Opening ' + resourceId);
        // add this resource to the list of expanded resources.
        this.setState(prevState => {
            if (prevState.openedResourceIds.includes(resourceId)) return null;
            else return {
                openedResourceIds: [...prevState.openedResourceIds, resourceId],
            }
        });
        const artifactId = this.state.artifacts[resourceId];
        const artifact = this.artifacts[artifactId];
        if (!this.childResourcesUnsubscribes.hasOwnProperty(artifactId)) {
            this.startListeningForChildResources(resourceId, artifact);
            this.startListeningForVersionChange(artifact);
        }
    }

    /**
     * 
     * @param {*} resourceId 
     */
    closeResource = resourceId => {
        const artifactId = this.state.artifacts[resourceId];
        const unsubscribe = this.childResourcesUnsubscribes[artifactId];
        delete this.childResourcesUnsubscribes[artifactId];
        if (unsubscribe) unsubscribe();
    }

    startListeningForVersionChange = artifact => {
        this.setState(prevState => produce(prevState, draft => {
            draft.artifactVersions[artifact.id] = artifact.getVersion();
        }));
        const unsubscribe = artifact.addVersionChangeListener(version => {
            this.setState(prevState => produce(prevState, draft => {
                draft.artifactVersions[artifact.id] = version;
            }));
        })
        this.versionChangesUnsubscribes[artifact.id] = unsubscribe;
    }

    stopListeningForVersionChange = artifactId => {
        if (this.versionChangesUnsubscribes.hasOwnProperty(artifactId)) {
            const unsubscribe = this.versionChangesUnsubscribes[artifactId];
            unsubscribe();
            delete this.versionChangesUnsubscribes[artifactId];
        }
    }

    /**
     * 
     * @param {*} resourceId 
     * @param {*} artifact 
     */
    startListeningForChildResources = (resourceId, artifact) => {
        let unsubscribed = false;
        let unlisten = null;

        this.childResourcesUnsubscribes[artifact.id] = () => {
            unsubscribed = true;
            unlisten && unlisten();
        };

        return artifact.getFileAsJson('/resource.json').then(resource => {
            if (unsubscribed) return;
            // do nothing if the artifact is not a module.
            if (resource.service) return;
            // from here we know it is a module
            unlisten = artifact.onSnapshot('/module.json', text => {

                const module = JSON.parse(text);

                const resourceUrisPromise = Object.entries(module.resources || {})
                    .map(([resourceName, resource]) => getStackArtifactBuildUri(resource.resource)
                        .then(buildUri => [`${resourceId}/${resourceName}`, buildUri]));

                return Promise.all(resourceUrisPromise).then(entries => {
                    this.setState(prevState => produce(prevState, draft => {
                        const resourceUris = Object.fromEntries(entries);
                        const newChildIds = Object.keys(resourceUris);
                        const prefix = `${resourceId}/`;
                        const length = prefix.length;
                        const curChildIds = Object.keys(draft.artifacts).filter(rId => rId.startsWith(prefix) && !rId.slice(length).includes('/'));
                        // remove resource id not in the list anymore
                        curChildIds.filter(rId => !newChildIds.includes(rId)).forEach(rId => {
                            // get children
                            Object.keys(draft.artifacts).filter(id => id === rId || id.startsWith(`${rId}/`)).forEach(rId => {
                                const artifactId = draft.artifacts[rId];
                                if (artifactId) {
                                    this.stopListeningForChildResources(artifactId);
                                    this.stopListeningForVersionChange(artifactId);
                                }
                                // delete from the list of artifacts that belongs to this stack
                                delete draft.artifacts[rId];
                                delete this.artifacts[artifactId]
    
                                // close the resource if the resource was opened
                                if (draft.openedResourceIds.includes(rId)) {
                                    draft.openedResourceIds = draft.openedResourceIds.filter(item => item !== rId);
                                }
                            });
                        });

                        // add or update new ones
                        Object.entries(resourceUris).forEach(([rId, buildUri]) => {
                            let artifact = this.artifactsByBuildIds[rId]?.[buildUri];

                            if (!artifact) {
                                artifact = new ResourceArtifact(resourceUris[rId]);
                                if (!this.artifactsByBuildIds.hasOwnProperty(rId)) this.artifactsByBuildIds[rId] = {};
                                this.artifactsByBuildIds[rId][buildUri] = artifact;
                            }

                            if (draft.openedResourceIds.includes(rId) && artifact.id !== draft.artifacts[rId]) {

                                // get children
                                Object.keys(draft.artifacts).filter(id => id === rId || id.startsWith(`${rId}/`)).forEach(rId => {
                                    const artifactId = draft.artifacts[rId];
                                    if (artifactId) {
                                        this.stopListeningForChildResources(artifactId);
                                        this.stopListeningForVersionChange(artifactId);
                                    }
                                    // delete from the list of artifacts that belongs to this stack
                                    delete draft.artifacts[rId];
                                    delete this.artifacts[artifactId]
        
                                    // close the resource if the resource was opened
                                    if (draft.openedResourceIds.includes(rId)) {
                                        draft.openedResourceIds = draft.openedResourceIds.filter(item => item !== rId);
                                    }
                                });

                                this.startListeningForChildResources(rId, artifact);
                            }
                            this.artifacts[artifact.id] = artifact;
                            draft.artifacts[rId] = artifact.id;
                        });
                    }));
                });
            });
        });
    }

    /**
     * 
     * @param {*} artifactId 
     */
    stopListeningForChildResources = artifactId => {
        if (this.childResourcesUnsubscribes.hasOwnProperty(artifactId)) {
            const unsubscribe = this.childResourcesUnsubscribes[artifactId];
            unsubscribe();
            delete this.childResourcesUnsubscribes[artifactId];
        }
    }

    /**
     * Forks an existing artifact to a new resource.
     * @param {string} resourceId - The ID of the new resource.
     * @param {string} uri - The URI of the artifact to fork.
     */
    forkStackArtifact = (resourceId, uri) => {
        console.log(`Forking artifact ${uri} to ${resourceId}`);
        const artifact = this.artifacts[uri];
        delete this.artifacts[uri];
        this.artifacts[resourceId] = artifact;

        // If it's the root resource, update the state with the new resourceId
        if (resourceId === this.props.stackId) {
            this.setState({ artifactUri: resourceId });
        }
    }

    /**
     * Returns all opened artifacts belonging to this stack.
     * @returns {Object<string, ResourceArtifact>} An object mapping resource IDs to artifacts.
     */
    fetchStackArtifacts = () => {
        return Object.fromEntries(
            Object.entries(this.state.artifacts)
                .filter(([resourceId]) => resourceId === this.props.stackId || resourceId.startsWith(`${this.props.stackId}/`))
                .map(([resourceId, artifactId]) => {
                    return [resourceId, this.artifacts[artifactId]];
                })
        );
    }

    /**
     * Saves the changes to the user bucket and uploads new or modified files if necessary.
     * @returns {Promise<Object>} The saved stack patch.
     */
    save = () => {
        return new Promise(resolve => this.setState({ isSaving: true }, resolve)).then(() => {
            const artifacts = this.fetchStackArtifacts();
            const changes = Object.fromEntries(Object.entries(artifacts)
                .map(([resourceId, artifact]) => [resourceId, artifact.getChanges()])
                .filter(([, changes]) => Object.keys(changes).length > 0));

            Object.keys(changes).forEach(resourceId => {
                const parts = resourceId.split('/');
                let parent = parts[0];
                parts.shift();
                parts.forEach(part => {
                    parent = parent ? `${parent}/${part}` : part;
                    if (!changes.hasOwnProperty(parent)) {
                        changes[parent] = {};
                    }
                });
            });

            return Promise.all(Object.entries(changes).map(([resourceId, changes]) => {
                const artifact = artifacts[resourceId];
                const filesToUpload = [...(changes.createdFiles || []), ...(changes.modifiedFiles || [])];

                return Promise.all(filesToUpload.map(filepath => {
                    return Promise.all([artifact.getOriginalRemoteFile(filepath), artifact.getFile(filepath)])
                        .then(([originalValue, value]) => {
                            let remote = null;
                            const remoteUserFile = this.remoteFileCache[filepath];

                            if (remoteUserFile) {
                                remote = { ...remoteUserFile };
                            } else if (originalValue) {
                                remote = { value: originalValue };
                            }

                            if (remote) {
                                const patch = dmp.patch_make(remote.value, value);
                                if (patch.length === 0) {
                                    const json = {};
                                    if (remote.cacheId) json.cacheId = remote.cacheId;
                                    return [filepath, json];
                                } else {
                                    const patchStr = dmp.patch_toText(patch);
                                    if (patchStr.length < value.length) {
                                        const json = { patch: patchStr };
                                        if (remote.cacheId) json.cacheId = remote.cacheId;
                                        return [filepath, json];
                                    }
                                }
                            }

                            const cacheId = uuid();
                            return uploadString(`${this.props.userId}/cache/${resourceId}/${cacheId}`, value, 'raw').then(() => {
                                this.remoteFileCache[filepath] = {
                                    cacheId,
                                    value,
                                };
                                return [filepath, { cacheId }];
                            });
                        });
                })).then(entries => {
                    const files = Object.fromEntries(entries);
                    const artifactPatch = { ...changes, files };
                    if (artifact.isRemoteArtifact()) artifactPatch.uri = artifact.getUri();
                    return [resourceId, artifactPatch];
                });
            })).then(entries => {
                const stackPatch = Object.fromEntries(entries);
                const stackPatchStr = JSON.stringify(stackPatch);

                if (this.lastSavedStackPatch === stackPatchStr) {
                    console.log('Save skipped. No change found with the previous stack patch.');
                    return JSON.parse(this.lastSavedStackPatch);
                }

                return uploadString(`${this.props.userId}/changes/${this.props.stackId}/${uuid()}.json`, stackPatchStr, 'raw').then(() => {
                    this.lastSavedStackPatch = stackPatchStr;
                    return new Promise(resolve => this.setState({ isSaving: false }, resolve)).then(() => {
                        console.log(`New version saved for ${this.props.stackId}`, stackPatch);
                        return stackPatch;
                    });
                });
            });
        });
    }

    /**
     * Builds a new version of the stack by saving changes and creating a build.
     * @returns {Promise<Object|boolean>} The new version if built, or false if no changes were made.
     */
    build = () => {
        return new Promise(resolve => this.setState({ isBuilding: true }, resolve)).then(() => {
            return this.save().then(stackPatch => {
                if (Object.entries(stackPatch).length === 0) {
                    console.log('Create version skipped, no change to apply');
                    return false;
                }

                return createStackBuild(this.props.stackId, stackPatch).then(([version, artifacts]) => {
                    console.log(`New version built for ${version.stackId}: ${version.buildId}`);
                    return new Promise(resolve => this.setState({ isBuilding: false }, resolve)).then(() => {
                        return version;
                    });
                });
            });
        });
    }

    /**
     * Renders the StackContext provider and passes down the necessary values and methods.
     * @returns {JSX.Element} The rendered provider component.
     */
    render() {
        return (
            <StackContext.Provider value={{
                stackId: this.props.stackId,
                stack: this.state.stack,
                artifacts: Object.fromEntries(Object.entries(this.state.artifacts).map(([resourceId, artifactId]) => [resourceId, this.artifacts[artifactId]])),
                artifactVersions: this.state.artifactVersions,
                openResource: this.openResource,
                closeResource: this.closeResource,
                openedResourceIds: this.state.openedResourceIds,
                forkStackArtifact: this.forkStackArtifact,
                save: this.save,
                build: this.build,
                isSaving: this.state.isSaving,
                isBuilding: this.state.isBuilding,
            }}>
                {this.props.children}
            </StackContext.Provider>
        );
    }
}

export default StackProvider;
