import React from 'react';
import { Outlet } from "react-router-dom";
import { getDatabase, onAuthStateChanged } from "../Firebase.js";
import AuthenticationContext from './AuthenticationContext.js';

/**
 * Manage the authentication, put the user and userInfo in a context.
 * It is a "wrapper" component (ie. Outlet usage)
 */
class AuthenticationProvider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: undefined, // the current user auth (from firebase auth)
            userInfo: undefined, // the user info from the firestore db
        }
        this.unsubscribeAuthState = null;
        this.unsubscribeUserInfoListener = null;
    }

    componentDidMount() {
        this.unsubscribeAuthState = onAuthStateChanged(this.onAuthStateChanged.bind(this));
    }

    componentWillUnmount() {
        if (this.unsubscribeAuthState) {
            this.unsubscribeAuthState();
            this.unsubscribeAuthState = null;
        }
        if (this.unsubscribeUserInfoListener) {
            this.unsubscribeUserInfoListener();
            this.unsubscribeUserInfoListener = null;
        }
    }

    onAuthStateChanged(user) {
        if (this.unsubscribeUserInfoListener && user?.uid != this.state.user?.uid) {
            this.unsubscribeUserInfoListener();
            this.unsubscribeUserInfoListener = null;
        }
        if (user) {
            // user is logged in
            this.unsubscribeUserInfoListener = getDatabase().collection('users').doc(user.uid).onSnapshot(doc => {
                this.setState({
                    user,
                    userInfo: doc.data(),
                });
            });
        } else {
            // user not logged in, redirect to signin page
            // this.props.navigate('/signin');
            this.setState({
                user: null,
                userInfo: null,
            });
        }
    }

    render() {
        if (!this.state.user || !this.state.userInfo) return <></> // Loading
        return <AuthenticationContext.Provider value={{
            user: this.state.user,
            userInfo: this.state.userInfo,
        }}>
            <Outlet />
        </AuthenticationContext.Provider>
    }
}

export default AuthenticationProvider;