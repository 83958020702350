// TODO: make an interface for firebase here in case of backend change.

import firebase from "firebase/app";
import "firebase/auth";
import 'firebase/firestore';
import "firebase/storage";

//const UPLOAD_TEMP_BUCKET_NAME = 'gs://blueforge-322008.appspot.com'; // TODO: to be configurable

// store the project to avoid double configuration.
let configured = false;
let STORAGE_BUCKET = null;

/**
 * Function to configure firebase backend.
 * @returns a promise that is resolved when the firebase app is initialized.
 */
export const configure = firebaseConfigUrl => {
    if (configured) throw new Error(`Firebase configuration already launched for project. ${firebaseConfigUrl}`);
    configured = true;
    return fetch(firebaseConfigUrl, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    })
        .then(response => response.json())
        .then(config => {
            STORAGE_BUCKET = config.storageBucket;
            //config.projectId = projectId // TODO: add it in firebase config 
            const firebaseApp = firebase.initializeApp(config);
            return firebaseApp
        });
}

// all following methods must be called after the configuration

/**
 * Sign an user.
 * @returns a Promise that resolve the credentials.
 */
export const signInWithEmailAndPassword = (email, password) => firebase.auth().signInWithEmailAndPassword(email, password);

/**
 * Utility function to use in order to be warned when the authentication state of the user has changed.
 * @param {*} callback The function to call when the authentication state of the user has changed.
 */
export const onAuthStateChanged = callback => firebase.auth().onAuthStateChanged(callback);

/**
 * Utility function that returns the id token of the logged user.
 * @returns the id token of the logged user.
 */
export const getIdToken = () => firebase.auth().currentUser.getIdToken(/* forceRefresh */ false);

/**
 * Returns the firestore database.
 * Must be called when the configuration is completed.
 * @returns 
 */
export const getDatabase = () => firebase.firestore();

/**
 * Returns te bucket with the provided name.
 * @param {*} name the name of the bucket without gs://
 * @returns a bucket instance
 */
//export const getBucket = name => firebase.app().storage('gs://' + name).ref();

// todo implement progress etc etc .. error handling ??
/*export const uploadString = (reference, str, format, metadata) => {
    return new Promise((resolve, reject) => {
        reference.firebaseRef.putString(str, format, metadata)
            .then((snapshot) => {
                console.log(snapshot);
                resolve(true); // 
            })
            .catch(err => reject(err));
    });
}*/

// todo implement progress etc etc .. error handling ??
/**
 * Upload file to the temporary user folder 
 * @param {*} path 
 * @param {*} str 
 * @param {*} format 
 * @param {*} metadata 
 * @returns 
 */
export const uploadString = (path, str, format, metadata) => {
    return firebase.app().storage(STORAGE_BUCKET).ref().child(path).putString(str, format, metadata)
            .then((snapshot) => {
                console.log(snapshot);
                return true 
            });
}

/**
 * Download a file from the temporary user bucket.
 * @param {*} path 
 * @returns 
 */
export const downloadJson = path => {
    return firebase.app().storage(STORAGE_BUCKET).ref().child(path).getDownloadURL()
        .then(url => {
            console.log(url)
            return fetch(url, {
                mode: 'cors', // Specify CORS mode
            }).then(response => response.json())
        })
}

