import { mdiFacebook, mdiFacebookGaming, mdiTwitter } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import styled from 'styled-components';
import AuthenticationContext from '../../authentication/AuthenticationContext.js';
import Section from '../../design/Section.js';
import StackContext from '../../stack/StackContext.js';
import { chain, withContext } from '../../utils/WithContext.js';
import Avatar from './Avatar.js';
import { ComponentName } from './Component.js';
import StackName from './StackName.js';
import TextPrimaryButton from '../../design/TextPrimaryButton.js';
import ComponentsDropDown from './ComponentsDropDown.js';

// Styled components for the AppBar
const AppBarContainer = styled.div`
    position: absolute;
    z-index: 1;
    //width: 100%;
    height: 70px;
    //border-bottom: 1px solid rgba(0,0,0,.05);
    //padding: 0 370px 0 370px;
    display: flex;
    align-items: center;
    gap: 16px;
    left: 50%;
    transform: translateX(-50%);
`;

const LogoLink = styled.a`
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #88a3bd;
    height: 32px;
    width: 32px;
    border-radius: 6px;
    transition: background-color .2s;
    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }
`;

const Spacer = styled.div`
    flex-grow: 1;
`;

const StackAppBar = chain(
    withContext(AuthenticationContext)(({ user }) => ({ user })),
    withContext(StackContext)(({ build }) => ({ build })),
)(
    class extends React.Component {
        render() {
            const { build } = this.props;

            return (
                <AppBarContainer>
                    
                    {/* Left Section: Logo and Name */}
                    <Section gap="8px" justify="flex-start">
                        <LogoLink href="https://www.blueforge.cloud">
                            <Icon path={mdiFacebookGaming} size={2} />
                        </LogoLink>
                        
                        {false && <StackName />}
                    </Section>

                    <Spacer />

                    {/* Center Section: Search Input */}
                    <Section flex="1 1 540px" justify="center" gap="4px">
                        {true && <ComponentsDropDown />}
                    </Section>

                    <Spacer />

                    {/* Right Section: Avatar and Action */}
                    <Section gap="24px" justify="flex-end">
                        <Avatar />
                        <TextPrimaryButton text={'Build'} onClick={build} />
                    </Section>

                </AppBarContainer>
            );
        }
    }
);

export default StackAppBar;