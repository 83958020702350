import React from 'react';
import { chain, withContext } from '../utils/WithContext';
import InfiniteContext from './InfiniteContext';
import InfiniteGestureListener from './InfiniteGestureListener';
import { getRectFromTwoCoordinates } from './InfiniteProvider';
import InfiniteSelected from './InfiniteSelected';
import InfiniteSelecting from './InfiniteSelecting';

/**
 * Style for the wrapper container.
 * @type {Object}
 */
const wrapperContainerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundColor: '#ffffff',
    background: 'radial-gradient(circle, transparent 20%, #e5e5f7 20%, #e5e5f7 80%, transparent 80%, transparent), radial-gradient(circle, transparent 20%, #e5e5f7 20%, #e5e5f7 80%, transparent 80%, transparent) 25px 25px, linear-gradient(#adadad 2px, transparent 2px) 0 -1px, linear-gradient(90deg, #adadad 2px, #e5e5f7 2px) -1px 0',
    backgroundSize: '50px 50px, 50px 50px, 25px 25px, 25px 25px',
    overflow: 'hidden',
};

/**
 * Style for the draggable container.
 * @type {Object}
 */
const draggableContainerStyle = {
    position: 'absolute',
    overflow: 'hidden',
    outline: '2px dashed rgba(0,0,0,.5)', // do not set a border ! use only outline
    backgroundColor: '#ffffff',
    backgroundSize: '16px 16px',
    backgroundImage: 'radial-gradient(#e4e4e4 .8px, transparent 0px)',
    backgroundPosition: '16px 16px',
};

/**
 * Infinite component for managing infinite scrolling and selection.
 */
const Infinite = chain(
    withContext(InfiniteContext)(({ container, delta, moveContainer, translateContainer, setSelectingRect, getNodesInRect, setSelectedNodes, selectedRect, selectedNodes, nodes, sizes }) =>
        ({ container, delta, moveContainer, translateContainer, setSelectingRect, getNodesInRect, setSelectedNodes, selectedRect, selectedNodes, nodes, sizes })),
)(class extends React.Component {

    /**
     * Constructs the Infinite component.
     * @param {Object} props - The component props.
     */
    constructor(props) {
        super(props);
        this.element = React.createRef();
        this.gesture = null;
    }

    /**
     * Lifecycle method called after the component is mounted.
     * Initializes the position of the container and sets up gesture listeners.
     */
    componentDidMount() {
        // Initial positioning (0,0) at the center of the div element
        var rect = this.element.current.parentNode.getBoundingClientRect();
        this.props.moveContainer({
            x: Math.round(rect.width / 2),
            y: Math.round(rect.height / 2)
        });
        // Create gesture listeners
        this.gesture = new InfiniteGestureListener(this.element.current);
        this.gesture.listenMouseEvents();
        this.setDragGestureListener();
        this.setSelectorGestureListener();
        // Call the componentDidMountOrUpdate function
        this.componentDidMountOrUpdate();
    }

    /**
     * Lifecycle method called after the component is updated.
     * Updates the element style.
     */
    componentDidUpdate() {
        this.componentDidMountOrUpdate();
    }

    /**
     * Method called after the component is mounted or updated.
     * Sets the element style.
     */
    componentDidMountOrUpdate() {
        this.setElementStyle();
    }

    /**
     * Lifecycle method called before the component is unmounted.
     * Unregisters the mouse event listeners.
     */
    componentWillUnmount() {
        this.gesture.unlistenMouseEvents();
    }

    /**
     * Sets the style of the element.
     */
    setElementStyle = () => {
        const s = this.element.current.style;
        s.width = this.props.container.width + 'px';
        s.height = this.props.container.height + 'px';
        s.top = this.props.container.top + 'px';
        s.left = this.props.container.left + 'px';
    }

    /**
     * Gets the coordinate from the viewport.
     * @param {number} x - x coordinate in the viewport referential.
     * @param {number} y - y coordinate in the viewport referential.
     * @returns {Object} The coordinates relative to the container.
     */
    getCoordinateFromViewport = (x, y) => {
        const rect = this.element.current.getBoundingClientRect();
        return {
            x: x - rect.left + this.props.container.left - this.props.delta.x,
            y: y - rect.top + this.props.container.top - this.props.delta.y,
        }
    }

    /**
     * Sets the drag gesture listener.
     * Handles dragging the container using mouse events.
     */
    setDragGestureListener = () => {
        let startPosition = {};
        this.gesture
            .when(({ event }) => event.which === 3)
            .onStart(() => {
                startPosition = {
                    x: this.props.delta.x,
                    y: this.props.delta.y,
                }
            })
            .onMove(({ delta }) => {
                this.props.moveContainer({
                    x: Math.round(startPosition.x + delta.x),
                    y: Math.round(startPosition.y + delta.y),
                });
            })
            .onStop(({ delta }) => {
                this.props.moveContainer({
                    x: Math.round(startPosition.x + delta.x),
                    y: Math.round(startPosition.y + delta.y),
                });
            });
    }

    /**
     * Sets the selector gesture listener.
     * Handles selecting nodes using mouse events.
     */
    setSelectorGestureListener = () => {
        let s1;
        this.gesture.when(({ event }) => event.which === 1)
            .onStart(({ event }) => {
                s1 = this.getCoordinateFromViewport(event.clientX, event.clientY);
                this.props.setSelectedNodes([]);
            })
            .onMove(({ event }) => {
                const s2 = this.getCoordinateFromViewport(event.clientX, event.clientY);
                this.props.setSelectingRect(getRectFromTwoCoordinates(s1, s2));
            })
            .onStop(({ event }) => {
                const s2 = this.getCoordinateFromViewport(event.clientX, event.clientY);
                this.props.setSelectingRect(null);
                const selectedNodes = this.props.getNodesInRect(getRectFromTwoCoordinates(s1, s2));
                this.props.setSelectedNodes(selectedNodes);
            });
    }

    /**
     * Renders the Infinite component.
     * @returns {JSX.Element} The rendered component.
     */
    render() {
        return <div style={wrapperContainerStyle}>
            <div
                ref={this.element}
                // Deactivate the default system context menu
                onContextMenu={event => {
                    if (!event.metaKey) event.preventDefault();
                    return false;
                }}
                style={draggableContainerStyle}>

                {/* The selecting rect */}
                <InfiniteSelecting />

                {this.props.children}

                {/* The selected rect */}
                <InfiniteSelected />

            </div>
        </div>
    }

});

export default Infinite;