import { mdiFormatText } from '@mdi/js';
import React from 'react';
import styled from "styled-components";
import IconButton from '../../design/IconButton.js';
import { ListItem } from '../../design/ListItem.js';
import TextPrimaryButton from '../../design/TextPrimaryButton.js';
import TitleBox from '../../design/TitleBox.js';
import { iconPlus } from '../../icons/LightIcons.js';
import PopupContext from '../../popup/PopupContext.js';
import ResourceContext from '../../stack/ResourceContext.js';
import { chain, withContext } from '../../utils/WithContext.js';


const PopupContainer = styled.div`
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 1000;
`;


const ResourceVariablesManager = chain(
    withContext(PopupContext)(({ open }) => ({ open })),
    withContext(ResourceContext)(({ component, removeVariable }) => ({ component, removeVariable })),
)(class extends React.Component {

    constructor(props) {
        super(props);
    }

    handleAddVariable = () => {
        this.props.open(<CreateVariable />)
    }

    render() {
        return (
            <TitleBox
                title={'Parameters'}
                buttons={<>
                    <IconButton path={iconPlus} onClick={this.handleAddVariable} />
                </>}>
                {Object.entries(this.props.component.variables || {}).map(([variableName, variable]) => (
                    <ListItem
                        name={variableName}
                        icon={mdiFormatText}
                        onDelete={() => this.props.removeVariable(variableName)} />
                ))}
            </TitleBox>
        );
    }
});

const CreateVariable = chain(
    withContext(PopupContext)(({ close }) => ({ close })),
    withContext(ResourceContext)(({ addVariable }) => ({ addVariable })),
)(class extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            variableName: null,
            type: null,
            defaultValue: null,
        }
    }

    handleChange = key => event => {
        this.setState({
            [key]: event.target.value,
        })
    }

    handleCreate = () => {
        this.props.addVariable(this.state.variableName, {
            type: this.state.type,
            defaultValue: this.state.defaultValue,
        });
        this.props.close();
    }

    render() {
        return (
            <div style={{ width: '600px', padding: '20px', display: 'flex', flexDirection: 'column', gap: '8px' }}>

                <span style={{ fontSize: '13px', fontWeight: 500 }}>
                    Add variable to this component
                </span>

                <input type={"text"} onChange={this.handleChange('variableName')} />
                <input type={"text"} onChange={this.handleChange('type')} />
                <input type={"text"} onChange={this.handleChange('defaultValue')} />

                <TextPrimaryButton text={'add'} onClick={this.handleCreate} />
            </div>
        )
    }

});

export default ResourceVariablesManager;
