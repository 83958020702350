import Icon from "@mdi/react";
import React from "react";
import styled from "styled-components";

// Styled-components pour le conteneur du bouton
const ButtonContainer = styled.div`
		display: flex;
		justify-content: center;
		align-items: center;
		width: ${(props) => props.width || '24px'};
		height: ${(props) => props.height || '24px'};
		border-radius: 6px;
		border-top-left-radius: ${(props) => props.stickyLeft ? '0px' : '6px'};
		border-top-right-radius: ${(props) => props.stickyRight ? '0px' : '6px'};
		border-bottom-left-radius: ${(props) => props.stickyLeft ? '0px' : '6px'};
		border-bottom-right-radius: ${(props) => props.stickyRight ? '0px' : '6px'};

		cursor: default;
		transition: background-color .2s;
        background-color: rgba(0, 0, 0, 0.025);

		&:hover {
				background-color: rgba(0, 0, 0, 0.05);
		}
`;

/**
 * IconButton Component
 */
export default class IconButton extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { width, height, onClick, stickyLeft, stickyRight, path } = this.props;

        return (
            <ButtonContainer width={width} height={height} stickyLeft={stickyLeft} stickyRight={stickyRight} onClick={onClick}>
                <Icon path={path} size={1} />
            </ButtonContainer>
        );
    }
}

