import DoUndoString from './RedoUndoString';

/**
 * Class representing a RedoUndo object for managing undo and redo operations on JSON objects.
 */
class RedoUndo {
    /**
     * Constructs a RedoUndo object.
     * @param {Object} object - The initial object to manage.
     * @param {Function} listener - The listener function to notify of changes.
     */
    constructor(object, listener) {
        this.doUndoString = new DoUndoString(JSON.stringify(object), listener);
    }

    /**
     * Gets the current state of the managed object.
     * @returns {Object} The current state of the object.
     */
    get() {
        return JSON.parse(this.doUndoString.get());
    }

    /**
     * Edits the managed object using the provided function.
     * @param {Function} fn - The function to apply to the current state of the object.
     */
    edit(fn) {
        var str = this.doUndoString.get();
        var object = fn(JSON.parse(str));
        this.doUndoString.set(JSON.stringify(object));
    }

    /**
     * Undoes the last change to the managed object.
     * @returns {Object} The state of the object after undoing the last change.
     */
    undo() {
        var value = this.doUndoString.undo();
        return JSON.parse(value);
    }

    /**
     * Redoes the last undone change to the managed object.
     * @returns {Object} The state of the object after redoing the last undone change.
     */
    redo() {
        var value = this.doUndoString.redo();
        return JSON.parse(value);
    }

    /**
     * Checks if the managed object has been modified.
     * @returns {boolean} True if the object has been modified, false otherwise.
     */
    isModified() {
        return this.doUndoString.isModified();
    }
}

export default RedoUndo;
