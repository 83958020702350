import { mdiDelete, mdiSwitch } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import { downloadServiceFile, getServiceArtifactBuildUri } from '../../backend/Services';
import InfiniteNodeContext from '../../infinite/InfiniteNodeContext';
import StackPageContext from '../../pages/stack/StackPageContext';
import StackContext from '../../stack/StackContext';
import { chain, withContext } from '../../utils/WithContext';

const ResourceNode = chain(
    withContext(StackContext)(({ stackId, artifacts }, props) => ({ stackId, artifact: artifacts[props.resourceId] })),
    withContext(StackPageContext)(({ openPageResource }) => ({ openPageResource })),
    withContext(InfiniteNodeContext)(({ selecting, selected }) => ({ selecting, selected })),
)(class extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            service: null,
            resourceClass: null,
            resource: null,

        }
        this.element = React.createRef();
    }

    componentDidMount() {
        this.componentDidMountOrUpdate();
    }

    componentDidUpdate(oldProps) {
        this.componentDidMountOrUpdate(oldProps);
    }

    componentDidMountOrUpdate(oldProps) {
        if (oldProps?.artifact !== this.props.artifact) {
            // we need to get the artifact here, in case the user does some modification on the file
            // get the buildId
            this.props.artifact.getFileAsJson('/resource.json').then(resourceClass => {
                const serviceUri = resourceClass.service;
                getServiceArtifactBuildUri(serviceUri).then(serviceBuildUri => {
                    if (serviceBuildUri) {
                        downloadServiceFile(serviceBuildUri, '/service.json').then(str => {
                            const service = JSON.parse(str);
                            this.setState({
                                resource: this.props.resource?.resource,
                                resourceClass,
                                service,
                            });
                        });
                    } else {
                        // module does not have a service
                        this.setState({
                            resource: this.props.resource?.resource,
                            resourceClass,
                        });
                    }
                });
            }).catch(err => {
                this.setState({
                    resource: this.props.resource?.resource,
                    resourceClass: {},
                })
            });
        }
    }

    open = open => {
        this.setState({ open });
    }

    handleOpen = () => {
        //alert(this.props.resourceId)
        this.props.openPageResource(this.props.resourceId);
    }


    render() {
        if (!this.state.resource && !this.state.service) return <div
            style={{
                width: 42,
                height: 42,
                background: 'red',
                borderRadius: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
            }}>

            <Icon path={mdiDelete} size={.7} onClick={event => {
                event.stopPropagation();
                this.props.handleRemove(this.props.resourceId)
            }} />

        </div>;

        if (!this.state.service) return <div
            onClick={() => this.handleOpen()}

            style={{
                width: 42,
                height: 42,
                background: '#DFDFDF',
                borderRadius: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
            }}>

            <Icon path={mdiDelete} size={.7} onClick={event => {
                event.stopPropagation();
                this.props.handleRemove(this.props.resourceId)
            }} />
            <Icon path={mdiSwitch} size={.7} onClick={event => {
                event.stopPropagation();
                this.props.handleSwitch(this.props.resourceId)
            }} />



        </div>;

        return <>
            <div ref={this.element} style={{ padding: '0px', position: 'relative' }}>
                <div
                    onClick={() => this.handleOpen()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '10px',
                        width: 180,
                        padding: '8px 16px 8px 8px',
                        transitionProperty: 'transform, border-color',
                        transitionDuration: '.07s',
                        transitionTimingFunction: 'ease-out',
                        borderRadius: '2px',
                        '&:hover': {
                            borderColor: '#0f87ff',
                            //transform: 'scale(1.08)',
                        },
                        //backgroundColor: this.props.selecting ? '#F8F8F8' : 'transparent',
                        backgroundColor: '#fcfcfd',
                        //background: 'rgba(255,255,255,.3)',
                        border: (this.props.selected || this.props.selecting) ? '1px solid #3959ff' : '1px solid #00000006',

                        boxShadow: 'rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;'
                    }}>
                    <div style={{
                        flexShrink: 0,
                        width: 42,
                        height: 42,
                        background: '#72d500', //this.state.service.color,
                        border: '1px solid #0000000f',
                        //backgroundImage: `linear-gradient(#F80, #00000020)`,
                        borderRadius: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#ffffff',
                    }}>
                        <Icon path={'M3 4H21V6H19.8L17.8 20H6.2L4.2 6H3V4M17.75 6H6.25L8.5 19H15.5L17.75 6Z' || this.state.service.icon} size={1}></Icon>
                    </div>
                    <div>
                        <div style={{ fontSize: 11, fontWeight: 600, color: '#234' }}>{this.props.resourceId.substring(this.props.resourceId.lastIndexOf('/') + 1) + ""}</div>
                        <div style={{ fontSize: 11, fontWeight: 300, color: '#9ca3af' }}>{"Bucket"}</div>
                        <Icon path={mdiDelete} size={.7} onClick={event => {
                            event.stopPropagation();
                            this.props.handleRemove(this.props.resourceId)
                        }} />
                    </div>

                </div>
            </div>


        </>
    }

});

export default ResourceNode;