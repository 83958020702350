import { produce } from 'immer';
import React from 'react';
import Infinite from '../../infinite/Infinite';
import InfiniteEdge from '../../infinite/InfiniteEdge';
import InfiniteNode from '../../infinite/InfiniteNode';
import InfiniteProvider from '../../infinite/InfiniteProvider';
import ResourceContext from '../../stack/ResourceContext';
import Button from '../../system/Button';
import { chain, withContext } from '../../utils/WithContext';
import ResourceNode from './ResourceNode';


const Module = chain(
    withContext(ResourceContext)(({ resourceId }) => ({ resourceId })),
)(class extends React.Component {

    constructor(props) {
        super(props);
    }

    onChange = nodes => {
        if (this.props.onChange) {
            this.props.onChange(produce(this.props.module, draft => {
                Object.entries(nodes).forEach(([resourceId, node]) => {
                    draft.resources[resourceId].layout = {
                        x: node.x,
                        y: node.y,
                    };
                })
            }));
        }
    }

    addResource = (resourceId, resource) => {
        this.props.onChange(produce(this.props.module, draft => {
            draft.resources[resourceId] = resource;
        }));
    }

    addDependency = (resourceId, dependency) => {
        this.props.onChange(produce(this.props.module, draft => {
            draft.resources[resourceId].dependencies = {
                ...draft[resourceId].dependencies,
                ...dependency,
            };
        }));
    }

    handleAddFunction = () => {
        this.addResource('function_' + Date.now(), {
            resource: 'function:v7',
            layout: {
                x: Math.round(Math.random() * 600 - 300),
                y: Math.round(Math.random() * 600 - 300),
            }
        })
    }

    handleAddModule = () => {
        this.addResource('module_' + Date.now(), {
            resource: 'module:v1',
            layout: {
                x: Math.round(Math.random() * 600 - 300),
                y: Math.round(Math.random() * 600 - 300),
            }
        })
    }

    handleRemove = resourceId => {
        this.props.onChange(produce(this.props.module, draft => {
            delete draft.resources[resourceId.substring(resourceId.lastIndexOf('/') + 1)];
        }));
    }

    handleSwitch = resourceId => {
        this.props.onChange(produce(this.props.module, draft => {
            draft.resources[resourceId.substring(resourceId.lastIndexOf('/') + 1)].resource = "function:v7";
        }));
    }

    render() {
        if (!this.props.module.hasOwnProperty('resources')) return <></>
        const resources = this.props.module.resources;

        return (
            <InfiniteProvider
                nodes={Object.fromEntries(Object.entries(resources).map(([resourceId, resource]) => [resourceId, resource.layout]))}
                onChange={this.onChange.bind(this)} >
                {false && <Button onClick={this.handleAddFunction.bind(this)}>Add Function</Button>}
                {false && <Button onClick={this.handleAddModule.bind(this)}>Add Module</Button>}
                <Infinite>
                    {/* Nodes */}
                    {Object.entries(resources).map(([resourceId, resource]) => (
                        <InfiniteNode key={resourceId} nodeId={resourceId}>
                            <ResourceNode key={resourceId} resourceId={this.props.resourceId + '/' + resourceId} resource={resource} handleRemove={this.handleRemove} handleSwitch={this.handleSwitch} />
                        </InfiniteNode>
                    ))}
                    {/* Edges */}
                    {Object.entries(resources)
                        .filter(([, resource]) => resource.dependencies && Object.keys(resource.dependencies).length > 0)
                        .map(([resourceId, resource]) => Object.entries(resource.dependencies).map(([dependencyName, dependencyResourceId]) => <InfiniteEdge
                            key={`${resourceId}-${dependencyResourceId}`}
                            start={resourceId}
                            end={dependencyResourceId}
                        />))}
                </Infinite>
            </InfiniteProvider>
        )
    }

});

export default Module;