import uuid from 'react-uuid';
import { downloadServiceFile, getServiceFiles } from '../backend/Services';
import { downloadStackFile, getStackFiles } from '../backend/Stacks';
import RedoUndo from './RedoUndo';
import RedoUndoString, { RedoUndoHistoryEvents } from './RedoUndoString';

/**
 * Class representing an Artifact, with version control and change tracking.
 *
 * This class provides the following functionalities:
 * 
 * 1. **Version Control**: 
 *    - Keeps track of the current version of an artifact.
 *    - Allows incrementing this version whenever changes are made.
 *    - Notifies listeners of version changes.
 *
 * 2. **File and Folder Management**: 
 *    - Creates, deletes, moves, and edits files and folders.
 *    - Maintains a structure of modified folders and files to track changes efficiently.
 *
 * 3. **Undo/Redo Functionality**: 
 *    - Implements undo and redo capabilities for changes made to files and folder structures.
 *    - Maintains lists to track changes and provides methods for applying undo and redo actions.
 *
 * 4. **Listeners**: 
 *    - Supports event listeners for various changes, such as file modifications and structural changes.
 *    - Allows external components to react to changes in real time.
 *
 * 5. **Remote and Local Artifacts**: 
 *    - Distinguishes between local and remote artifacts.
 *    - Fetches file contents from a remote server when necessary and handles local modifications.
 *
 * 6. **Change Tracking**: 
 *    - Provides a method to compute all changes made, including deleted, created, and modified files and folders.
 *    - Facilitates tracking the state of the artifact over time.
 */
export default class Artifact {

    /**
     * Construct an artifact object.
     * @param {string} uri - The URI of the artifact.
     * @param {Object|null} remote - Remote configuration, containing `uri`, `downloadFile`, and `getFiles`.
     */
    constructor(uri, remote) {
        this.id = uuid();
        this.uri = uri;
        this.remote = remote;
        this.version = 0;

        // Folders
        this.cachedFolders = {};
        this.cachedFoldersPromises = {};
        this.modifiedFolders = new RedoUndo({}, value => {
            this.structureChangeListeners.forEach(fn => fn(value));
        });

        // Files
        this.modifiedFiles = {};
        this.undoList = [];
        this.redoList = [];

        // Listeners
        this.versionChangeListeners = [];
        this.structureChangeListeners = [];
        this.fileChangeListeners = {};
        this.fileChangeListener = filepath => event => {
            if (!this.fileChangeListeners.hasOwnProperty(filepath)) return;
            this.fileChangeListeners[filepath].forEach(fn => fn(event));
        };

        // Download cache
        this.downloadCache = {};
    }

    // VERSIONING METHODS

    /**
     * Increment the version number of the artifact and notify listeners.
     * @param {string} action - The action that caused the version increment.
     */
    incrementVersion(action) {
        this.version++;
        clearTimeout(this.versionTimeout_);
        this.versionTimeout_ = setTimeout(() => {
            this.versionChangeListeners.forEach(fn => fn(this.version, action));
        }, 0);
    }

    /**
     * Get the current version of the artifact.
     * @returns {number} The current version number.
     */
    getVersion() {
        return this.version;
    }

    /**
     * Get the URI of the artifact.
     * @returns {string} The URI of the artifact.
     */
    getUri() {
        return this.uri;
    }

    /**
     * Check if the artifact is local (not remote).
     * @returns {boolean} True if the artifact is local, false otherwise.
     */
    isLocalArtifact() {
        return !this.remote;
    }

    /**
     * Check if the artifact is remote.
     * @returns {boolean} True if the artifact is remote, false otherwise.
     */
    isRemoteArtifact() {
        return this.remote !== null;
    }

    // LISTENER MANAGEMENT METHODS


    /**
     * 
     * @param {*} fn 
     */
    addVersionChangeListener(fn) {
        this.versionChangeListeners.push(fn);
        const unsubscribe = () => this.removeVersionChangeListener(fn);
        return unsubscribe;
    }

    /**
     * 
     * @param {*} fn 
     */
    removeVersionChangeListener(fn) {
        this.versionChangeListeners.splice(this.versionChangeListeners.findIndex(f => f === fn), 1);
    }

    /**
     * Add a new listener for structure changes (file/folder creation/deletion).
     * @param {Function} fn - The listener function to add.
     */
    addStructureChangeListener(fn) {
        this.structureChangeListeners.push(fn);
        const unsubscribe = () => this.removeStructureChangeListener(fn);
        return unsubscribe;
    }

    /**
     * Remove a structure change listener.
     * @param {Function} fn - The listener function to remove.
     */
    removeStructureChangeListener(fn) {
        this.structureChangeListeners.splice(this.structureChangeListeners.findIndex(f => f === fn), 1);
    }

    /**
     * Add a new listener for file changes.
     * @param {string} filepath - The path of the file to listen to.
     * @param {Function} fn - The listener function to add.
     */
    addFileChangeListener(filepath, fn) {
        if (!this.fileChangeListeners.hasOwnProperty(filepath)) {
            this.fileChangeListeners[filepath] = [];
        }
        this.fileChangeListeners[filepath].push(fn);
        const unsubscribe = () => this.removeFileChangeListener(filepath, fn);
        return unsubscribe;
    }

    /**
     * Remove a file change listener.
     * @param {string} filepath - The path of the file.
     * @param {Function} fn - The listener function to remove.
     */
    removeFileChangeListener(filepath, fn) {
        if (!this.fileChangeListeners.hasOwnProperty(filepath)) return;

        const arr = this.fileChangeListeners[filepath];
        arr.splice(arr.findIndex(f => f === fn), 1);
        if (arr.length === 0) {
            delete this.fileChangeListeners[filepath];
        }
    }



    /**
     * Listen for file change 
     * @param {*} filepath 
     * @param {*} fn 
     * @returns 
     */
    onSnapshot(filepath, fn) {
        let unsubscribed = false;
        const listener = event => {
            fn(event.value);
        };
        const unsubscribe = () => {
            unsubscribed = true;
            this.removeFileChangeListener(filepath, listener);
        }
        this.getFile(filepath)
            .then(text => {
                if (unsubscribed) return;
                fn(text);
            }).finally(() => {
                this.addFileChangeListener(filepath, listener);
            });
        return unsubscribe;
    }



    // UNDO/REDO METHODS

    /**
     * Undo the last structure change.
     */
    undoLastChangeOnStructure() {
        this.incrementVersion('undoLastChangeOnStructure');
        this.modifiedFolders.undo();
    }

    /**
     * Redo the last structure change.
     */
    redoLastChangeOnStructure() {
        this.incrementVersion('redoLastChangeOnStructure');
        this.modifiedFolders.redo();
    }

    /**
     * Check if the structure has been modified.
     * @returns {boolean} True if the structure is modified, false otherwise.
     */
    isStructureModified() {
        return this.modifiedFolders.isModified();
    }

    /**
     * Undo the last change on files.
     * @param {number} duration - The time duration for undoing changes.
     */
    undoLastChangeOnFiles(duration = 200) {
        this.incrementVersion('undoLastChangeOnFiles');
        // Get the list of undo actions to apply
        let undo = this.undoList[this.undoList.length - 1];
        if (!undo) return;
        let list = [undo];
        let index = 1;
        const timestampLimit = undo.timestamp - duration;
        while (true) {
            index++;
            undo = this.undoList[this.undoList.length - index];
            if (!undo || undo.timestamp < timestampLimit) break;
            list.push(undo);
        }
        list.forEach(p => this.modifiedFiles[p.filepath].undo());
    }

    /**
     * Redo the last change on files.
     */
    redoLastChangeOnFiles() {
        this.incrementVersion('redoLastChangeOnFiles');
        // Get the list of redo actions to apply
        let redo = this.redoList[this.redoList.length - 1];
        if (!redo) return;
        let list = [redo];
        let index = 1;
        const timestampLimit = redo.timestamp + 200;
        while (true) {
            index++;
            redo = this.redoList[this.redoList.length - index];
            if (!redo || redo.timestamp > timestampLimit) break;
            list.push(redo);
        }
        list.forEach(p => this.modifiedFiles[p.filepath].redo());
    }

    /**
  * Reverts the last change made to a file.
  * @param {string} filepath - The path of the file to undo the last change.
  * @returns {*} The result of the undo operation.
  */
    undoLastChangeOnFile(filepath) {
        this.incrementVersion('undoLastChangeOnFile');
        if (this.modifiedFiles.hasOwnProperty(filepath)) {
            return this.modifiedFiles[filepath].undo();
        }
    }

    /**
     * Reapplies the last undone change to a file.
     * @param {string} filepath - The path of the file to redo the last change.
     * @returns {*} The result of the redo operation.
     */
    redoLastChangeOnFile(filepath) {
        this.incrementVersion('redoLastChangeOnFile');
        if (this.modifiedFiles.hasOwnProperty(filepath)) {
            return this.modifiedFiles[filepath].redo();
        }
    }

    // ACTION METHODS

    /**
     * Retrieves the contents of a folder asynchronously.
     * @async
     * @param {string} path - The path of the folder to retrieve.
     * @returns {Promise<Object>} A promise resolving to the folder's content.
     */
    getFolder(path) {
        var modifiedFolders = this.modifiedFolders.get();
        if (modifiedFolders.hasOwnProperty(path)) {
            return Promise.resolve(modifiedFolders[path]);
        }
        else if (this.cachedFoldersPromises.hasOwnProperty(path)) {
            return this.cachedFoldersPromises[path];
        }
        else {
            if (this.isLocalArtifact()) {
                const folder = { folder: path, folders: [], files: [] };
                this.modifiedFolders.edit(modifiedFolders => {
                    modifiedFolders[path] = folder;
                    return modifiedFolders;
                });
                return Promise.resolve(folder);
            } else {
                // Cache to prevent multiple backend calls for the same folder.
                this.cachedFoldersPromises[path] = this.remote.getFiles(this.uri, path).then(json => {
                    this.cachedFolders[path] = json;
                    this.structureChangeListeners.forEach(fn => fn(path));
                    return json;
                });
                return this.cachedFoldersPromises[path];
            }
        }
    }

    /**
     * Creates a new folder asynchronously.
     * @async
     * @param {string} filepath - The full path of the folder to create.
     * @returns {Promise<void>} A promise that resolves once the folder is created.
     */
    createFolder(filepath) {
        this.incrementVersion('createFolder');
        var path = filepath.substring(0, filepath.lastIndexOf('/'));
        path = path.substring(0, path.lastIndexOf('/') + 1);
        const name = filepath.substring(path.length, filepath.lastIndexOf('/'));
        return this.getFolder(path).then(({ folder, files, folders }) => {
            if (!folders.includes(name)) {
                var value = {
                    folder,
                    files,
                    folders: [...folders, name],
                };
                this.modifiedFolders.edit(modifiedFolders => {
                    modifiedFolders[path] = value;
                    return modifiedFolders;
                });
                this.structureChangeListeners.forEach(fn => fn(path));
            } else {
                return Promise.reject(`Folder ${filepath} already exists.`);
            }
        });
    }

    /**
     * Moves a folder from one path to another.
     * @param {string} filepath - The current path of the folder to move.
     * @param {string} toFilepath - The target path for the folder.
     */
    moveFolder(filepath, toFilepath) {
        // TODO: Implement folder moving functionality
    }

    /**
     * Deletes a folder asynchronously.
     * @async
     * @param {string} filepath - The full path of the folder to delete.
     * @returns {Promise<void>} A promise that resolves once the folder is deleted.
     */
    deleteFolder(filepath) {
        this.incrementVersion('deleteFolder');
        filepath = filepath.substring(0, filepath.length - 1);
        const path = filepath.substring(0, filepath.lastIndexOf('/') + 1);
        const name = filepath.substring(filepath.lastIndexOf('/') + 1);
        return this.getFolder(path).then(({ folder, files, folders }) => {
            if (folders.includes(name)) {
                var arr = [...folders];
                arr.splice(arr.findIndex(f => f === name), 1);
                var value = {
                    folder,
                    files,
                    folders: arr,
                };
                this.modifiedFolders.edit(modifiedFolders => {
                    modifiedFolders[path] = value;
                    return modifiedFolders;
                });
                this.structureChangeListeners.forEach(fn => fn(path));
            } else {
                return Promise.reject("Folder does not exist.");
            }
        });
    }

    /**
     * Retrieves the original version of a remote file, if available.
     * @param {string} filepath - The path of the file.
     * @returns {Promise<*>} The original file content from the remote artifact, or null if newly created.
     */
    getOriginalRemoteFile(filepath) {
        if (this.isLocalArtifact()) return Promise.resolve(null);
        const path = filepath.substring(0, filepath.lastIndexOf('/') + 1);
        const name = filepath.substring(path.length);
        const cache = this.cachedFolders[path] || {};
        const cacheFiles = cache.files || [];
        if (!cacheFiles.includes(name)) return Promise.resolve(null);
        return this.remote.downloadFile(this.uri, filepath);
    }

    /**
     * Checks if a file exists in the specified path.
     * @param {string} filepath - The path of the file to check.
     * @returns {Promise<boolean>} A promise resolving to true if the file exists, otherwise false.
     */
    isFileExists(filepath) {
        const folderpath = filepath.substring(0, filepath.lastIndexOf('/') + 1);
        const filename = filepath.substring(folderpath.length);
        return this.getFolder(folderpath).then(({ files }) => files && files.includes(filename));
    }

    /**
     * Retrieves the content of a file asynchronously.
     * @async
     * @param {string} filepath - The path of the file to retrieve.
     * @returns {Promise<string>} A promise that resolves to the file content.
     */
    getFile(filepath) {
        if (this.modifiedFiles.hasOwnProperty(filepath)) {
            var doUndoStr = this.modifiedFiles[filepath];
            return Promise.resolve(doUndoStr.get());
        }
        if (this.isLocalArtifact()) {
            return Promise.reject(`File ${filepath} does not exist.`);
        }
        return this.remote.downloadFile(this.uri, filepath);
    }

    /**
     * Parses and retrieves the content of a file as JSON.
     * @param {string} filepath - The path of the file to retrieve and parse.
     * @returns {Promise<Object>} A promise resolving to the parsed JSON object.
     */
    getFileAsJson(filepath) {
        return this.getFile(filepath).then(text => {
            try {
                return JSON.parse(text);
            } catch (err) {
                console.error('Unable to parse the following JSON:', text);
                throw err;
            }
        });
    }

    /**
     * Checks if the specified file has been modified.
     * @param {string} filepath - The path of the file to check.
     * @returns {boolean} True if the file has been modified, false otherwise.
     */
    isModifiedFile(filepath) {
        return this.modifiedFiles.hasOwnProperty(filepath);
    }

    /**
     * Creates a new file with the provided content.
     * @async
     * @param {string} filepath - The path of the new file to create.
     * @param {string} text - The content to write into the new file.
     * @returns {Promise<string>} A promise resolving to the created file content.
     */
    createFile(filepath, text) {
        this.incrementVersion('createFile ' + filepath);
        const path = filepath.substring(0, filepath.lastIndexOf('/') + 1);
        const name = filepath.substring(path.length);
        return this.getFolder(path).then(({ folder, files, folders }) => {
            if (!files.includes(name)) {
                var value = {
                    folder,
                    files: [...files, name],
                    folders,
                };
                this.modifiedFolders.edit(modifiedFolders => {
                    modifiedFolders[path] = value;
                    return modifiedFolders;
                });
                this.modifiedFiles[filepath] = new RedoUndoString(
                    text,
                    this.fileChangeListener(filepath),
                    createHistoryListener(this, filepath),
                );
                this.structureChangeListeners.forEach(fn => fn(path));
                return text;
            } else {
                return Promise.reject("File already exists.");
            }
        });
    }

    /**
     * Asynchronously edits the content of a file.
     * If the file has been modified before, retrieves the previous state.
     * Otherwise, downloads the original content from the remote server if applicable.
     * The new content is set and tracked in the undo/redo system.
     * 
     * @async
     * @param {string} filepath - The path of the file to be edited.
     * @param {string} text - The new content of the file.
     * @returns {Promise<void>} - Resolves once the file content is updated, or if no changes are required.
     */
    editFile(filepath, text) {
        console.log('editFile', this.uri, this.id, text);
        this.incrementVersion('editFile');
        let doUndoString;
        let originalValue;
        let promise;
        if (this.modifiedFiles.hasOwnProperty(filepath)) {
            doUndoString = this.modifiedFiles[filepath];
            originalValue = doUndoString.get();
            promise = Promise.resolve(originalValue);
        } else if (this.isRemoteArtifact()) {
            promise = this.remote.downloadFile(this.uri, filepath);
        }
        return promise.then(originalValue => {
            if (text == originalValue) {
                console.log('Edit skipped, same value');
                return; // nothing to edit ...
            }
            if (!doUndoString) {
                doUndoString = new RedoUndoString(
                    originalValue,
                    this.fileChangeListener(filepath),
                    createHistoryListener(this, filepath)
                );
                this.modifiedFiles[filepath] = doUndoString;
            }

            doUndoString.set(text); // set the new value
        });
    }

    /**
     * Moves a file from one path to another.
     * 
     * @param {string} filepath - The current path of the file.
     * @param {string} toFilepath - The destination path to move the file to.
     * @returns {void}
     */
    moveFile(filepath, toFilepath) {
        // TODO
    }

    /**
     * Asynchronously deletes a file from the system.
     * Updates the parent folder's list of files and notifies listeners of the structural changes.
     * 
     * @async
     * @param {string} filepath - The path of the file to be deleted.
     * @returns {Promise<void>} - Resolves once the file has been successfully deleted, or rejects if the file does not exist.
     */
    deleteFile(filepath) {
        this.incrementVersion('deleteFile ' + filepath);
        // get the parent folder   
        const path = filepath.substring(0, filepath.lastIndexOf('/') + 1);
        const name = filepath.substring(path.length);
        return this.getFolder(path).then(({ folder, files, folders }) => {
            if (files.includes(name)) {
                var arr = [...files];
                arr.splice(arr.findIndex(f => f === name), 1);
                var value = {
                    folder,
                    files: arr,
                    folders,
                }
                this.modifiedFolders.edit(modifiedFolders => {
                    modifiedFolders[path] = value;
                    return modifiedFolders;
                });
                this.structureChangeListeners.forEach(fn => fn(path));
            } else {
                return Promise.reject("File does not exist.");
            }
        });
    }

    /**
     * Computes all the changes made to the artifact, including file and folder creations, deletions, and modifications.
     * 
     * @returns {Object} - An object containing arrays of changes:
     * - `deletedFolders`: List of deleted folders.
     * - `deletedFiles`: List of deleted files.
     * - `createdFolders`: List of created folders.
     * - `createdFiles`: List of created files.
     * - `modifiedFiles`: List of modified files.
     */
    getChanges() {
        console.log('getChanges', this.uri, this.id);
        var changes = {
            deletedFolders: [],
            deletedFiles: [],
            createdFolders: [],
            createdFiles: [],
            modifiedFiles: [],
        }
        var modifiedFolders = this.modifiedFolders.get();
        Object.entries(modifiedFolders).forEach(([path, { files, folders }]) => {
            var cache = this.cachedFolders[path] || {};
            var cacheFiles = cache.files || [];
            var cacheFolders = cache.folders || [];
            changes.createdFiles.push(...files.filter(name => !cacheFiles.includes(name)).map(name => `${path}${name}`));
            changes.deletedFiles.push(...cacheFiles.filter(name => !files.includes(name)).map(name => `${path}${name}`));
            changes.createdFolders.push(...folders.filter(name => !cacheFolders.includes(name)).map(name => `${path}${name}`));
            changes.deletedFolders.push(...cacheFolders.filter(name => !folders.includes(name)).map(name => `${path}${name}`));
        });
        changes.modifiedFiles = Object.entries(this.modifiedFiles)
            .filter(([path, undoRedoStr]) => !changes.createdFiles.includes(path) && !changes.deletedFiles.includes(path) && undoRedoStr.isModified())
            .map(([path]) => path);

        // only keep entries with at least one element.
        return Object.fromEntries(Object.entries(changes).filter(([k, v]) => v.length > 0));
    }
}

/**
 * Create a history listener for redo/undo events.
 * @param {Object} artifact - The artifact object to track changes on.
 * @param {string} filepath - The filepath being tracked.
 * @returns {Function} A function to handle different redo/undo events.
 */
const createHistoryListener = (artifact, filepath) => {
    return ({ type, timestamp }) => {
        if (type === RedoUndoHistoryEvents.REDO_ADDED) {
            artifact.redoList.push({ filepath, timestamp });
        } else if (type === RedoUndoHistoryEvents.UNDO_ADDED) {
            artifact.undoList.push({ filepath, timestamp });
        } else if (type === RedoUndoHistoryEvents.REDO_REMOVED) {
            artifact.redoList.splice(artifact.redoList.findLastIndex(o => o.filepath === filepath), 1);
        } else if (type === RedoUndoHistoryEvents.UNDO_REMOVED) {
            artifact.undoList.splice(artifact.undoList.findLastIndex(o => o.filepath === filepath), 1);
        } else if (type === RedoUndoHistoryEvents.REDO_CLEARED) {
            Object.entries(artifact.modifiedFiles).forEach(([o, redoUndoStr]) => redoUndoStr.clear());
            artifact.redoList = [];
        }
    };
};

export class ResourceArtifact extends Artifact {
    constructor(uri) {
        super(uri, {
            downloadFile: downloadStackFile,
            getFiles: getStackFiles,
        });
    }
}

export class ServiceArtifact extends Artifact {
    constructor(uri) {
        super(uri, {
            downloadFile: downloadServiceFile,
            getFiles: getServiceFiles,
        });
    }
}

export class LocalArtifact extends Artifact {
    constructor(uri) {
        super(uri);
    }
}
