import DiffMatchPatch from 'diff-match-patch';

const dmp = new DiffMatchPatch();

/**
 * Enum for Redo and Undo history events.
 * @enum {string}
 */
export const RedoUndoHistoryEvents = {
    REDO_CLEARED: 'REDO_CLEARED',
    UNDO_ADDED: 'UNDO_ADDED',
    REDO_ADDED: 'REDO_ADDED',
    UNDO_REMOVED: 'UNDO_REMOVED',
    REDO_REMOVED: 'REDO_REMOVED',
}

/**
 * Enum for Redo and Undo value events.
 * @enum {string}
 */
export const RedoUndoValueEvents = {
    SET: 'set',
    UNDO: 'undo',
    REDO: 'redo',
}

/**
 * Class representing a redo/undo string value with history tracking.
 */
class RedoUndoString {

    /**
     * Creates an instance of RedoUndoString.
     * @param {string} value - The initial value of the string.
     * @param {function} valueListener - Listener function for value changes.
     * @param {function} historyListener - Listener function for history changes.
     */
    constructor(value, valueListener, historyListener) {
        this.version = 0;
        this.value = value; // the current value
        this.undoPatches = []; // all patches for undo
        this.redoPatches = []; // all patches for redo (removed when a new value is set)

        // Listener for history change
        this.onHistoryChange = (type, timestamp) => {
            historyListener && historyListener({
                timestamp, // timestamp of the initial event
                type
            });
        };

        // Listener for value change
        this.onValueChange = event => {
            this.version += 1; // Increment version on value change
            valueListener && valueListener(event);
        };
    }

    /**
     * Gets the current value.
     * @returns {string} - The current string value.
     */
    get() {
        return this.value;
    }

    /**
     * Gets the current version number.
     * @returns {number} - The version number of the string.
     */
    getVersion() {
        return this.version;
    }

    /**
     * Clears the redo patches.
     */
    clear() {
        this.redoPatches = [];
    }

    /**
     * Sets a new value and records the changes for undo/redo functionality.
     * @param {string} value - The new value to set.
     */
    set(value) {
        if (value === this.value) return; // No change
        // Clear the redo patches
        if (this.redoPatches.length > 0) {
            this.clear();
            this.onHistoryChange(RedoUndoHistoryEvents.REDO_CLEARED);
        }
        // Timestamp of the event
        const timestamp = new Date().getTime();
        // Create an undo patch
        const undoPatch = dmp.patch_make(value, this.value);
        const undoPatchFn = () => {
            const [undoValue, result] = dmp.patch_apply(undoPatch, this.value);
            // Create a redo patch
            const redoPatch = dmp.patch_make(undoValue, this.value);
            const redoPatchFn = () => {
                const [redoValue, result] = dmp.patch_apply(redoPatch, this.value);
                this.undoPatches.push([undoPatchFn, timestamp]);
                this.onHistoryChange(RedoUndoHistoryEvents.UNDO_ADDED, timestamp);
                this.value = redoValue;
                this.onValueChange({ type: RedoUndoValueEvents.REDO, value: this.value });
                return this;
            };

            this.redoPatches.push([redoPatchFn, timestamp]);
            this.onHistoryChange(RedoUndoHistoryEvents.REDO_ADDED, timestamp);
            this.value = undoValue;
            this.onValueChange({ type: RedoUndoValueEvents.UNDO, value: this.value });
            return this;
        };

        this.undoPatches.push([undoPatchFn, timestamp]);
        this.onHistoryChange(RedoUndoHistoryEvents.UNDO_ADDED, timestamp);
        this.value = value;
        this.onValueChange({ type: RedoUndoValueEvents.SET, value: this.value });
    }

    /**
     * Undoes the last action and returns the current value.
     * @returns {string} - The current string value after undo.
     */
    undo() {
        const patch = this.undoPatches.pop();
        if (patch != null) {
            const [fn, timestamp] = patch;
            this.onHistoryChange(RedoUndoHistoryEvents.UNDO_REMOVED, timestamp);
            fn();
        }
        return this.value;
    }

    /**
     * Redoes the last undone action and returns the current value.
     * @returns {string} - The current string value after redo.
     */
    redo() {
        const patch = this.redoPatches.pop();
        if (patch != null) {
            const [fn, timestamp] = patch;
            this.onHistoryChange(RedoUndoHistoryEvents.REDO_REMOVED, timestamp);
            fn();
        }
        return this.value;
    }

    /**
     * Checks if there have been any modifications made.
     * @returns {boolean} - True if modified, false otherwise.
     */
    isModified() {
        return this.undoPatches.length > 0;
    }
}

export default RedoUndoString;