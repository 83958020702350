import React from 'react';
import { createHost } from '../backend/Api.js';
import Button from '../system/Button.js';
import FormElement from '../system/FormElement.js';
import Input from '../system/Input.js';
import Title from '../system/Title.js';

class CreateHost extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            creating: false
        }
        this.values = {};
    }

    onChange = name => event => this.values[name] = event.target.value;

    setCreating = creating => this.setState({ creating })

    create() {
        this.setCreating(true);
        createHost({
            organization: 'default',
            hostName: this.values.name,
            backendPairingUrl: this.values.url,
        }).then(host => {
            this.setCreating(false);
            this.props.navigate(`/hosts/${host.hostId}`);
        }).catch(err => {
            console.err(err); // TODO
            this.setCreating(false);
        });
    }

    render() {
        return (
            <div sx={{
                display: 'flex',

                margin: 'auto',
                gap: '20px',
                width: 400,
            }}>
                <Title>Create a new host</Title>

                <FormElement name="Name">
                    <Input sx={{ width: '100%' }} readOnly={this.state.creating} onChange={this.onChange('name').bind(this)} />
                </FormElement>

                <FormElement name="Pairing URL">
                    <Input sx={{ width: '100%' }} readOnly={this.state.creating} onChange={this.onChange('url').bind(this)} />
                </FormElement>

                <Button onClick={this.create.bind(this)}>Create new host</Button>
            </div>
        );
    }
}

export default CreateHost;