import * as React from 'react';
import { downloadServiceFile, getServiceArtifactBuildUri } from '../backend/Services';
import ResourceContext from './ResourceContext';
import { produce } from 'immer';

class ResourceProvider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            component: null,
            serviceId: null,
            service: null,
        }
        this.unsubscribe = null;
    }

    componentDidMount() {
        this.componentDidMountOrUpdate();
    }

    componentDidUpdate(oldProps) {
        this.componentDidMountOrUpdate(oldProps);
    }

    componentDidMountOrUpdate(oldProps) {
        if (this.props.artifact && oldProps?.artifact !== this.props.artifact) {
            if (this.unsubscribe) this.unsubscribe();
            this.unsubscribe = this.props.artifact.onSnapshot('/resource.json', componentStr => {
                const component = JSON.parse(componentStr);
                const serviceUri = component.service;
                if (!serviceUri) {
                    this.setState({ component, serviceId: 'module' });
                } else {
                    getServiceArtifactBuildUri(serviceUri).then(serviceBuildUri => {
                        if (serviceBuildUri) {
                            const serviceId = serviceBuildUri.split(':')[0];
                            downloadServiceFile(serviceBuildUri, '/service.json').then(str => {
                                const service = JSON.parse(str);
                                this.setState({ component, serviceId, service });
                            });
                        } else {
                        }
                    });
                }
            });
        }
    }

    addDependency = (dependencyName, dependency) => {
        const json = produce(this.state.component, draft => {
            if (!draft.dependencies) draft.dependencies = {};
            draft.dependencies[dependencyName] = dependency;
        });
        const jsonString = JSON.stringify(json, null, 2);
        this.props.artifact.editFile('/resource.json', jsonString);
    }

    removeDependency = dependencyName => {
        const json = produce(this.state.component, draft => {
            delete draft.dependencies[dependencyName];
            if (Object.keys(draft.dependencies).length === 0) delete draft.dependencies;
        });
        const jsonString = JSON.stringify(json, null, 2);
        this.props.artifact.editFile('/resource.json', jsonString);
    }

    addVariable = (variableName, variable) => {
        const json = produce(this.state.component, draft => {
            if (!draft.variables) draft.variables = {};
            draft.variables[variableName] = variable;
        });
        const jsonString = JSON.stringify(json, null, 2);
        this.props.artifact.editFile('/resource.json', jsonString);
    }

    removeVariable = variableName => {
        const json = produce(this.state.component, draft => {
            delete draft.variables[variableName];
            if (Object.keys(draft.variables).length === 0) delete draft.variables;
        });
        const jsonString = JSON.stringify(json, null, 2);
        this.props.artifact.editFile('/resource.json', jsonString);
    }

    /**
     * 
     * @returns 
     */
    render() {
        return <ResourceContext.Provider value={{
            resourceId: this.props.resourceId,
            artifact: this.props.artifact,
            component: this.state.component,
            serviceId: this.state.serviceId,
            service: this.state.service,
            addDependency: this.addDependency,
            removeDependency: this.removeDependency,
            addVariable: this.addVariable,
            removeVariable: this.removeVariable,
        }}>
            {this.props.children}
        </ResourceContext.Provider>
    }

};

export default ResourceProvider;