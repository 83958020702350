import { mdiChevronDown, mdiChevronRight, mdiCodeBraces, mdiDeleteOutline, mdiFileOutline, mdiInformationOutline } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import IconButton from '../design/IconButton';
import AutoFocusInput from '../system/AutoFocusInput';
import { chain, withContext } from '../utils/WithContext';
import ArtifactBrowserContext from './ArtifactBrowserContext';
import ArtifactBrowserEvents from './ArtifactBrowserEvents';

/*export const getFileIcon = pathOrName => {
    const name = pathOrName.substring(pathOrName.indexOf('/') + 1);
    if (name === 'resource.json') return mdiCubeOutline;
    else if (name.endsWith('.js')) return mdiLanguageJavascript;
    else if (name.endsWith('.json')) return mdiCodeBraces;
    else if (name.endsWith('.py')) return mdiLanguagePython;
    else if (name.endsWith('.java')) return mdiAlphaJBoxOutline;
    else if (name.endsWith('.md')) return mdiInformationOutline;
    else return mdiTextLong;
}*/

/**
 * The folder component.
 */
const ArtifactBrowserFolder = chain(
    withContext(ArtifactBrowserContext)(({
        artifact,
        setSelected,
        isSelected,
        isAddFile,
        isAddFolder,
        clearAddFileOrFolder,
        onFileTreeChange,
        expand,
        collapse,
        isExpanded,
    }) => ({
        artifact,
        setSelected,
        isSelected,
        isAddFile,
        isAddFolder,
        clearAddFileOrFolder,
        onFileTreeChange,
        expand,
        collapse,
        isExpanded,
    })),
)(class extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            folder: null,
        };
    }

    synchronizeFolder = () => {
        if (this.props.isExpanded(this.props.path)) {
            if (!this.state.folder) {
                this.props.artifact.getFolder(this.props.path).then(folder => {
                    this.setState({
                        folder,
                    });
                });
            }
        }
    }

    componentDidMount() {
        this.props.artifact.addStructureChangeListener(path => {
            // only update if the change is on this folder
            if (path === this.props.path) {
                this.props.artifact.getFolder(this.props.path).then(folder => {
                    this.setState({
                        folder,
                    });
                });
            }
        });
        this.synchronizeFolder();
    }

    componentDidUpdate() {
        this.synchronizeFolder();
    }

    handleClick = () => {
        this.props.setSelected(this.props.path);
        if (this.props.isExpanded(this.props.path)) {
            this.props.collapse(this.props.path);
        } else {
            this.props.expand(this.props.path);
        }
    }

    handleCreateFolder = value => {
        if (!value) {
            this.props.clearAddFileOrFolder();
        } else {
            const filepath = `${this.props.path}${value}/`;
            return this.props.artifact.createFolder(filepath).then(() => {
                this.props.clearAddFileOrFolder();
                this.props.setSelected(filepath);
                this.props.expand(filepath);
                if (this.props.onFileTreeChange) {
                    this.props.onFileTreeChange(ArtifactBrowserEvents.FOLDER_CREATED, filepath);
                }
            }).catch(err => console.log(err))
        }
    }

    handleCreateFile = value => {
        if (!value) {
            this.props.clearAddFileOrFolder();
        } else {
            const filepath = `${this.props.path}${value}`;
            return this.props.artifact.createFile(filepath, '').then(() => {
                this.props.clearAddFileOrFolder();
                this.props.setSelected(filepath);
                if (this.props.onFileTreeChange)
                    this.props.onFileTreeChange(ArtifactBrowserEvents.FOLDER_CREATED, filepath);
            }).catch(err => console.log(err))
        }
    }

    handleDelete = () => {
        this.props.artifact.deleteFolder(this.props.path);
        if (this.props.onFileTreeChange) {
            this.props.onFileTreeChange(ArtifactBrowserEvents.FOLDER_DELETED, this.props.path);
        }
    }

    render() {
        return <div style={{ display: 'flex', width: '100%' }}>
            {this.props.name && <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    height: 24,
                    borderRadius: '3px',
                    fontWeight: 400,
                    color: '#187ae8',
                    flexDirection: 'row',
                    paddingLeft: `${4 + this.props.level * 8}px`,
                    cursor: 'pointer',
                    backgroundColor: this.props.isSelected(this.props.path) ? '#01f79930' : 'transparent',
                    '&:hover': {
                        backgroundColor: 'rgba(0,0,0,.08)'
                    }
                }}
                onClick={this.handleClick.bind(this)}>
                <Icon path={this.props.isExpanded(this.props.path) ? mdiChevronDown : mdiChevronRight} size={.6} />

                <div style={{ flexGrow: 1, fontSize: 13, fontWeight: 400 }}>{this.props.name}</div>
                {false && <IconButton path={mdiDeleteOutline} onClick={this.handleDelete.bind(this)} />}
            </div>}

            <div style={{
                display: this.props.isExpanded(this.props.path) ? 'block' : 'none',
                width: '100%',
            }}>
                {this.state.folder ? <>
                    {this.props.isAddFolder(this.props.path) && <AutoFocusInput
                        key={`addFolder:${this.props.path}`}
                        sx={{ marginLeft: `${(this.props.level + 1) * 8}px` }}
                        onChange={this.handleCreateFolder.bind(this)}
                        onBlur={() => this.props.clearAddFileOrFolder()}
                    />}
                    {this.state.folder.folders.map(f => <ArtifactBrowserFolder
                        key={`${this.props.path}${f}/`}
                        level={this.props.level + 1}
                        path={`${this.props.path}${f}/`}
                        name={f}
                    />)}
                    {this.props.isAddFile(this.props.path) && <AutoFocusInput
                        key={`addFile:${this.props.path}`}
                        sx={{ marginLeft: `${(this.props.level + 1) * 8}px` }}
                        onChange={this.handleCreateFile.bind(this)}
                        onBlur={() => this.props.clearAddFileOrFolder()}
                    />}
                    {this.state.folder.files.map(f => <ArtifactBrowserFile
                        key={`${this.props.path}${f}`}
                        level={this.props.level + 1}
                        path={`${this.props.path}${f}`}
                        name={f}
                    />)}
                </> :
                    <>Loading</>
                }
            </div>
        </div>
    }

});

/**
 * The file component.
 */
const ArtifactBrowserFile = chain(
    withContext(ArtifactBrowserContext)(({ artifact, setSelected, isSelected, onFileTreeChange }) => ({ artifact, setSelected, isSelected, onFileTreeChange })),
)(class extends React.Component {

    handleClick = () => {
        this.props.setSelected(this.props.path);
    }

    handleDelete = () => {
        this.props.artifact.deleteFile(this.props.path);
        if (this.props.onFileTreeChange) {
            this.props.onFileTreeChange(ArtifactBrowserEvents.FILE_DELETED, this.props.path);
        }
    }

    getFileIconAndColor = filename => {
        const extension = filename.substring(filename.lastIndexOf('.') + 1).toLowerCase();
        if (extension === 'json') {
            return ['#F80', mdiCodeBraces];
        } else if (extension === 'md') {
            return ['#2b5c9c', mdiInformationOutline];
        }
        return ['blue', mdiFileOutline];
    }

    render() {
        const [color, icon] = this.getFileIconAndColor(this.props.name);
        return <div
            style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                height: 24,
                borderRadius: '3px',
                //letterSpacing: '0.05504px',
                //fontSize: 12,
                //fontWeight: 300,
                color: '#2874ca',
                flexDirection: 'row',
                paddingLeft: `${4 + (this.props.level - 1) * 8}px`,
                cursor: 'pointer',
                backgroundColor: this.props.isSelected(this.props.path) ? '#01b4f722' : 'transparent',
                border: this.props.isSelected(this.props.path) ? '1px solid #01b4f7' : '1px solid transparent',
                transition: 'background-color .1s',
                '&:hover': {
                    backgroundColor: this.props.isSelected(this.props.path) ? '#01b4f722' : '#01b4f712',
                }
            }}
            onClick={this.handleClick.bind(this)}>
            <Icon style={{ color }} path={icon} size={.8} />
            <div style={{ flexGrow: 1 }}>{this.props.name}</div>
            {false && <IconButton path={mdiDeleteOutline} onClick={this.handleDelete.bind(this)} />}
        </div>
    }

});

/**
 * The root component
 */
const ArtifactBrowser = () => <ArtifactBrowserFolder level={0} path={'/'} />

export default ArtifactBrowser;