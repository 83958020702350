import * as React from 'react';
import { chain, withContext } from '../../utils/WithContext.js';
import AppContext from '../../app/AppContext.js';
import AppPageContext from './AppPageContext.js';


const AppPageProvider = chain(
    withContext(AppContext)(({ appId, app }) => ({ appId, app })),
)(
    class extends React.Component {

        constructor(props) {
            super(props);
        }

        render() {
            if (!this.props.app) return <>Loading</>;

            return (
                <AppPageContext.Provider value={{
                }}>
                    {this.props.children}
                </AppPageContext.Provider>
            );
        }
    })

export default AppPageProvider;