import React from 'react';
import { createApp } from '../backend/Api.js';
import Button from '../system/Button.js';
import FormElement from '../system/FormElement.js';
import Input from '../system/Input.js';
import Title from '../system/Title.js';

class CreateApp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            creating: false
        }
        this.values = {
            hostId: '@default/my-first-host',
            stackId: '@default/alexandra',
        };
    }

    onChange = name => event => this.values[name] = event.target.value;

    setCreating = creating => this.setState({ creating })

    create() {
        this.setCreating(true);
        createApp({
            organization: 'default',
            appName: this.values.name,
            hostId: this.values.hostId,
            stackId: this.values.stackId,
        }).then(app => {
            this.setCreating(false);
            this.props.navigate(`/apps/${app.appId}`);
        }).catch(err => {
            console.err(err); // TODO
            this.setCreating(false);
        });
    }

    render() {
        return (
            <div sx={{
                display: 'flex',
                margin: 'auto',
                gap: '20px',
                width: 400,
            }}>
                <Title>Create a new app</Title>

                <FormElement name="Name">
                    <Input sx={{ width: '100%' }} readOnly={this.state.creating} onChange={this.onChange('name').bind(this)} />
                </FormElement>

                <FormElement name="Host">
                    <Input sx={{ width: '100%' }} readOnly={this.state.creating} onChange={this.onChange('hostId').bind(this)} defaultValue={'@default/my-first-host'} />
                </FormElement>

                <FormElement name="Stack">
                    <Input sx={{ width: '100%' }} readOnly={this.state.creating} onChange={this.onChange('stackId').bind(this)} defaultValue={'@default/alexandra'} />
                </FormElement>

                <Button onClick={this.create.bind(this)}>Create new app</Button>
            </div>
        );
    }
}

export default CreateApp;