import { mdiSafeSquareOutline, mdiSquareOutline } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import styled from 'styled-components';
import ResourceContext from '../../stack/ResourceContext';
import { chain, withContext } from '../../utils/WithContext';
import { ResourceIcon } from '../../design/ResourceIcon';

const Container = styled.div`
    display: flex;
    align-items: center;
`;

const ModuleText = styled.span`
    font-weight: 300;
    color: #9ca3af;
`;

const ServiceText = styled.span`
    font-weight: 300;
    color: #9ca3af;
`;

const ComponentText = styled.span`
    font-weight: 600;
    font-size: 16px;
    //font-family: IBM Plex Mono,ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace;
`;

const HighlightedText = styled.span`
    font-weight: ${(props) => props.fontWeight || '400'};
    //color: #191919;
    //font-family: IBM Plex Mono,ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace;
`;

const StyledSeparator = styled.span`
    font-weight: 100;
    color: #9ca3af;
    margin: 0 2px;
`;

const IconContainer = styled.div`
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    //background: rgb(221,221,221);
    //background: linear-gradient(180deg, rgba(221,221,221,1) 0%, rgba(217,217,239,1) 100%);
    //clip-path: polygon(15% 0, 85% 0, 100% 15%, 100% 85%, 85% 100%, 15% 100%, 0 85%, 0 15%);
    //color: #373739;
`;

export const ComponentIcon = ({ path }) => {
    return (
        <IconContainer>
            <Icon path={path} size={1} />
        </IconContainer>

    );
};

export const ComponentName = chain(
    withContext(ResourceContext)(({ resourceId, service }) => ({ resourceId, service })),
)(class extends React.Component {

    render() {
        const resourceId = this.props.resourceId;
        const icon = this.props.service ? this.props.service.icon : mdiSquareOutline;
        let parts = resourceId.split('/');
        // get and remove organization
        const organization = resourceId[0].startsWith('@') ? parts.shift() : '@default';
        const componentStackName = parts.shift();
        return (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
            }}>

                <Icon path={icon} size={.8} />

                <Container>
                    {parts.length === 0 ? <>
                        <ModuleText>{organization}</ModuleText>
                        <StyledSeparator>/</StyledSeparator>
                        <HighlightedText fontWeight={400}>{componentStackName}</HighlightedText>
                    </>
                        : <>
                            <ModuleText>{'~'}</ModuleText>
                            <StyledSeparator>/</StyledSeparator>
                            {parts.map((part, index) => (
                                <React.Fragment key={index}>
                                    {index < parts.length - 1 ? (
                                        <>
                                            <ModuleText>{part}</ModuleText>
                                            <StyledSeparator>/</StyledSeparator>
                                        </>
                                    ) : (
                                        <HighlightedText fontWeight={400}>{part}</HighlightedText>
                                    )}
                                </React.Fragment>
                            ))}
                        </>
                    }
                </Container>
        
            </div>
        );
    }
})




export const ComponentCard = chain(
    withContext(ResourceContext)(({ resourceId, serviceId, service }) => ({ resourceId, serviceId, service })),
)(class extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        const resourceId = this.props.resourceId;
        const icon = this.props.service ? this.props.service.icon : mdiSquareOutline;
        const parts = resourceId.split('/');
        const name = parts[parts.length - 1]; 

        return (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                //backgroundColor: 'rgba(0,0,0,.04)',
                //borderRadius: '6px',
            }}>

                {false && <ComponentIcon path={icon} />}
                <ComponentText>{name}</ComponentText>
            </div>
        );
    }
})
