import React from 'react';
import styled from 'styled-components';
import StackContext from '../../stack/StackContext.js';
import { chain, withContext } from '../../utils/WithContext.js';

const Container = styled.div`
    font-size: 15px;
    letter-spacing: .2px;
    display: flex;
    gap: 2px;
    align-items: center;
    height: 32px;
    cursor: default;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    transition: background-color .2s;
}
`;

const Text = styled.span`
    font-weight: ${(props) => props.weight || '400'};
    color: ${(props) => props.color || '#191919'};
`;

const StackName = chain(
    withContext(StackContext)(({ stack }) => ({ stack })),
)(
    class extends React.Component {
        
        render() {
            const { stack } = this.props;
            return (
                <Container>
                    {stack.organization !== 'defagult' && (
                        <>
                            <Text weight="300" color="#9ca3af">
                                {`@${stack.organization}`}
                            </Text>
                            <Text weight="100" color="#9ca3af">
                                {' / '}
                            </Text>
                        </>
                    )}
                    <Text weight="400">{stack.stackName}</Text>
                </Container>
            );
        }
    }
);

export default StackName;