/**
 * 
 * 
 *
 */
export default class InfiniteGestureListener {

    constructor(element) {
        this.element = element;
        this.isListening = false;
        this.listeners = [];
        this.trigger = event => {
            let taken = false;
            this.listeners.forEach(listener => taken |= listener(event));
            if (taken) {
                event.stopPropagation();
                event.preventDefault();
             } // first element to take it keep it, there is no propagation to the parent !
        }
    }

    /**
     * 
     */
    listenMouseEvents() {
        this.isListening = true;
        this.element.addEventListener('mousedown', this.trigger);
    }

    /**
     * 
     */
    unlistenMouseEvents() {
        this.isListening = false;
        this.element.removeEventListener('mousedown', this.trigger);
    }

    /**
     * 
     * @param {*} startCondition 
     * @returns 
     */
    when(startCondition) {
        let noop = () => {};
        let cancelCondition = null;
        let start = noop, move = noop, stop = noop, cancel = noop;
        let raf = null;
        
        let startListener = (event) => {
            if (!startCondition || !startCondition({event, duration: 0, distance: 0, delta: {x: 0, y: 0}})) return false;
            const ms = new Date();
            const clientX = event.clientX;
            const clientY = event.clientY;
            let moveListener = (event) => {
                const delta = {
                    x: event.clientX - clientX,
                    y: event.clientY - clientY,
                }
                const duration = new Date() - ms;
                const distance = Math.sqrt(delta.x * delta.x + delta.y * delta.y);
                if (cancelCondition != null && cancelCondition({event, duration, distance, delta})) {
                    window.removeEventListener('mousemove', moveListener);
                    window.removeEventListener('mouseup', upListener, { once: true });
                    if (this.isListening) cancel({cancelled: true, event, duration, distance, delta});
                } else {
                    if (this.isListening) move({event, duration, distance, delta});
                }
                raf = null;
            }
            
            let upListener = (event) => {
                const delta = {
                    x: event.clientX - clientX,
                    y: event.clientY - clientY,
                }
                const duration = new Date() - ms;
                const distance = Math.sqrt(delta.x * delta.x + delta.y * delta.y);
                window.removeEventListener('mousemove', moveListener)
                if (cancelCondition != null && cancelCondition({event, duration, distance, delta})) { 
                    cancel({cancelled: true, event, duration, distance, delta}); 
                    return;
                }
                if (this.isListening) stop({cancelled: false, event, duration, distance, delta});
            }
            window.addEventListener('mousemove', moveListener);
            window.addEventListener('mouseup', upListener, { once: true });

            start({event});
            return true;
        }
        
        this.listeners.push(startListener);

        var o = {
            cancelWhen: (fn) => { cancelCondition = fn; return o; },
            onStart: (fn) => { start = fn; return o; },
            onMove: (fn) => { move = fn; return o; },
            onStop: (fn) => { stop = fn; return o; },
            onCancel: (fn) => { cancel = fn; return o; },
        }

        return o;
    }
}