import React from 'react';

class SystemInput extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { sx, type, onChange, children, defaultValue } = this.props;
        return <input
            type={'text'}
            defaultValue={defaultValue}
            onChange={onChange}
            sx={{
                ...sx,
                padding: '4px 8px',
                background: 'rgba(255,255,255, .15)',
                border: '1px solid rgba(0,0,0,.06)',
                color: '#000',
                fontSize: 15,
                '&.Mui-focused': {
                    outline: '1px solid blue'
                }
            }}>{children}</input>
    }

}

export default SystemInput;
