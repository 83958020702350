import React from 'react';
import image from "./avatar.png";

class Avatar extends React.Component {

    render() {
        return (
            <div
                onClick={() => alert('Not implemented')}
                style={{
                    width: 24,
                    height: 24,
                    borderRadius: '12px',
                    backgroundImage: `url(${image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    '&:hover': {
                        cursor: 'pointer',
                    }
                }}
            />
        )
    }
    
};

export default Avatar;