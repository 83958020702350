import * as React from 'react';

export const ErrorHandlerContext = React.createContext();

/**
 * Put a raiseError in a context.
 * Allow all components to raise errors.
 */
class ErrorHandler extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            type: null,
            name: null,
            details: null,
        }
    }

    /**
     * 
     * @param {*} name 
     * @param {*} details 
     */
    raiseError = (name, details) => {
        this.setState({
            type: 'ERROR',
            name,
            details
        });
    }

    render() {
        return <ErrorHandlerContext.Provider value={{
            raiseError: this.raiseError.bind(this)
        }}>
            {this.props.children}

            {this.state.type === 'ERROR' && <>{'TODO'}</>}
        </ErrorHandlerContext.Provider>
    }
}

export default ErrorHandler;