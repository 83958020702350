import React from 'react';
import styled from 'styled-components';
import PopupContext from './PopupContext';

const NO_CONTENT = <>No content</>;

const Overlay = styled.div`
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.2);
`;

const PopupContainer = styled.div`
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Popup = styled.div`
    background-color: #fbfbfb;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    border-radius: 16px;
    border: 1px solid rgba(0,0,0,.03);
`;


class PopupProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            opened: false,
        };
        this.content = NO_CONTENT;
    }

    open = (content) => {
        this.content = content;
        this.setState({ opened: true });
    };

    close = () => {
        this.content = NO_CONTENT;
        this.setState({ opened: false });
    };

    render() {
        return (
            <PopupContext.Provider
                value={{
                    opened: this.state.opened,
                    open: this.open.bind(this),
                    close: this.close.bind(this),
                }}
            >
                {this.props.children}
                {this.state.opened && (
                    <>
                        <Overlay onClick={this.close} />
                        <PopupContainer>
                            <Popup>{this.content}</Popup>
                        </PopupContainer>
                    </>
                )}
            </PopupContext.Provider>
        );
    }
}

export default PopupProvider;
