import {
    downloadServiceFile as _downloadServiceFile,
    getServiceFiles as _getServiceFiles,
    getService as _getService,
    getServicePublication as _getServicePublication,
    createServiceBuild as _createServiceBuild,
} from "./Api";
import BackendCache from "./BackendCache";
import localStringCache from "./LocalStringCache";
import { isBuildIdVersionFormat, isBuiltArtifactUri, isPublicationIdVersionFormat, isPublishedArtifactUri } from "./Utils";

// Session caches
const CACHE = new BackendCache();

/**
 * 
 * @param {*} serviceId 
 * @returns 
 */
export const getService = _getService;

/**
 * 
 * @param {*} serviceId 
 * @param {*} patch 
 * @returns 
 */
export const createServiceBuild = _createServiceBuild;

/**
 * can be cached has no changes are possible on a publication.
 * @param {*} serviceId  
 * @param {*} publicationId v1, v2...
 * @returns 
 */
export const getServicePublication = (serviceId, publicationId) => {
    const uri = `${serviceId}/${publicationId}`;
    const cacheKey = `servicePublications/${uri}`;
    const cachedValue = localStringCache.get(cacheKey);

    if (cachedValue) return Promise.resolve(JSON.parse(cachedValue));

    if (!CACHE.PUBLICATIONS_PROMISES.hasOwnProperty(uri)) {
        CACHE.PUBLICATIONS_PROMISES[uri] = _getServicePublication(serviceId, publicationId).then(publication => {
            localStringCache.set(cacheKey, JSON.stringify(publication));
            return publication;
        });
    }
    return CACHE.PUBLICATIONS_PROMISES[uri];
}

/**
 * Return the artifact uri
 * my-service:v1 => my-service:e3f3867065e6e03fe97b66c0980610a023db7568 
 * my-service => my-service:e3f3867065e6e03fe97b66c0980610a023db7568 
 * my-service:e3f3867065e6e03fe97b66c0980610a023db7568 => my-service:e3f3867065e6e03fe97b66c0980610a023db7568 
 * Note: we cache also the latest to have the same version for all resources in the current session. 
 * @param {*} uri       
 * @returns the the artifact uri
 */
export const getServiceArtifactBuildUri = uri => {
    const [serviceId, versionId] = uri.split(':');
    if (!CACHE.BUILD_URIS_PROMISES.hasOwnProperty(uri)) {
        let promise;
        if (!versionId) {
            promise = getService(serviceId)
                .then(service => `${serviceId}:${service.lastPublication.buildId}`);    
        } else if (isPublishedArtifactUri(uri)) {
            promise = getServicePublication(serviceId, versionId)
                .then(publication => `${serviceId}:${publication.buildId}`);
        } else if (isBuiltArtifactUri(uri)) {
            promise = Promise.resolve(uri);
        } else {
            throw new Error(`Service uri is not valid. Got ${uri}`);
        }
        CACHE.BUILD_URIS_PROMISES[uri] = promise;
    }
    return CACHE.BUILD_URIS_PROMISES[uri];
}

/**
 * 
 * @param {*} uri                
 * @param {*} filepath 
 * @returns 
 */
export const downloadServiceFile = (uri, filepath) => {
    return getServiceArtifactBuildUri(uri).then(uri => {
        const [artifactId, buildId] = uri.split(':');
        const cacheKey = `services/${artifactId}/${buildId}.zip${filepath}`;
        const cachedValue = localStringCache.get(cacheKey);

        if (cachedValue) return cachedValue;

        if (!CACHE.DOWNLOAD_FILES_PROMISES.hasOwnProperty(cacheKey)) {
            CACHE.DOWNLOAD_FILES_PROMISES[cacheKey] = _downloadServiceFile(artifactId, buildId, filepath).then(str => {
                localStringCache.set(cacheKey, str);
                return str;
            });
        }

        return CACHE.DOWNLOAD_FILES_PROMISES[cacheKey];
    });
}

/**
 * 
 * @param {*} uri             
 * @param {*} folder 
 * @returns 
 */
export const getServiceFiles = (uri, folder) => {
    return getServiceArtifactBuildUri(uri).then(uri => {
        const [artifactId, buildId] = uri.split(':');
        const cacheKey = `services/${artifactId}/${buildId}.zip${folder}`;
        const cachedValue = localStringCache.get(cacheKey);

        if (cachedValue) return JSON.parse(cachedValue);

        if (!CACHE.DOWNLOAD_FOLDERS_PROMISES.hasOwnProperty(cacheKey)) {
            CACHE.DOWNLOAD_FOLDERS_PROMISES[cacheKey] = _getServiceFiles(artifactId, buildId, folder).then(json => {
                localStringCache.set(cacheKey, JSON.stringify(json));
                return json;
            });
        }

        return CACHE.DOWNLOAD_FOLDERS_PROMISES[cacheKey];
    });
}